import React, { useState, useContext } from "react";
import { AccountContext } from "../events/Account";
import Logo from "../assets/suyabakan_logo_svg.svg";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DeleteMyAccount = () => {
  return (
    <div className="p-5 h-full w-screen relative flex flex-col bg-[#F8F9FA] ">
      <div className="fixed p-5 z-0 top-0 h-[60vh] max-sm:h-[30rem] w-full  left-0  justify-center    bg-cover bg-no-repeat  rounded-2xl ">
        <div className="w-full h-full bg-bgoverview13 brightness-[.5] contrast-[.75] bg-bottom bg-cover rounded-2xl "></div>
      </div>
      <div className="relative z-10 w-full h-60 px-6 py-10 flex flex-col items-center justify-center ">
        <p className="text-4xl font-semibold text-white text-center mb-4 ">
          Delete My Account
        </p>
        <p className="text-sm  text-white text-center ">
          If you want to delete your account, please contact us.
        </p>
      </div>
      <div className=" relative z-10 w-[87%] mx-auto shadow-myShadow1   lg:w-[25rem] bg-white backdrop-blur-lg  rounded-2xl ">
        <img src={Logo} className=" mx-auto my-8 w-28  " />
        {/* <h3 className="pt-4 text-2xl text-center clear-both">
          {t("welcome")} &#128075;
        </h3> */}
        <form className="px-5  pb-8 mb-4  rounded">
          <div className="mb-6 text-left text-sm text-textLight">
            {/* <a
              className="w-full text-sm px-20 py-3 uppercase font-semibold text-white bg-gradient-to-r from-[#62CBF8] to-[#3157F5] rounded-lg  focus:outline-none focus:shadow-outline"
              href="mailto:serkan.ersoy@yt.com.tr?subject=Account%20Deletion%20Request&body=Hello%2C%0D%0A%0D%0AI%20would%20like%20to%20request%20the%20permanent%20deletion%20of%20my%20account%20based%20on%20the%20following%20details%3A%0D%0A%0D%0AFull%20Name%3A%20%5BYour%20Full%20Name%5D%0D%0AEmail%20Address%3A%20%5BAccount%20Email%20Address%5D%0D%0AReason%20for%20Account%20Deletion%20(Optional)%3A%20%5BYour%20Reason%5D%0D%0A%0D%0APlease%20proceed%20with%20permanently%20deleting%20my%20account%20and%20removing%20all%20associated%20personal%20data%20from%20your%20platform.%0D%0A"
            >
              Contact Us
            </a> */}
            <p className="border p-2 mb-2"> To: info@suyabakan.com</p>
            <p className="border p-2 mb-2"> Subject: Account Deletion Request</p>
            <p className="border p-2">
              {" "}
              Hello, <br/><br/>I would like to request the permanent deletion of my
              account based on the following details:<br/><br/> Full Name: [Your Full
              Name]<br/><br/> Email Address: [Account Email Address]<br/><br/> Reason for Account
              Deletion (Optional): [Your Reason]<br/><br/> Please proceed with permanently
              deleting my account and removing all associated personal data from
              your platform.
            </p>
          </div>
          <hr className="mb-6 border-t" />
          <div className="text-center">
            <Link
              className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
              to="/"
            >
              return to app
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DeleteMyAccount;
