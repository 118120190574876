import React, {
  useMemo,
  useEffect,
  useContext,
  useCallback,
  useState,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { AccountContext } from "../events/Account";
import People from "../assets/people.jpg";
import Stars from "../assets/stars.png";
import {
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from "material-react-table";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  ListItemIcon,
  Typography,
  Modal,
} from "@mui/material";
import {
  Delete,
  Edit,
  OpenInNew,
  Download,
  Settings,
  ConfirmationNumber,
  ConfirmationNumberTwoTone,
  SaveAltRounded,
  Save,
  Done,
} from "@mui/icons-material";
function Profile() {
  const { t, i18n } = useTranslation();
  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
  };

  const { apiKey, userMail, user_id } = useContext(AccountContext);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [createGroupModalOpen, setCreateGroupModalOpen] = useState(false);
  const navigate = useNavigate();
  const [field, setField] = useState([]);
  const [userACL, setUserACL] = useState([]);
  const [allSelectedFields, setAllSelectedFields] = useState([]);
  const [user, setUser] = useState();
  const [selectedField, setSelectedField] = useState();
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedDeleteRow, setSelectedDeleteRow] = useState();
  const [selectedFarmRow, setSelectedFarmRow] = useState();
  const [targetUser, setTargerUser] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [tableData, setTableData] = useState([]);
  const [allACL, setAllAcl] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isDeviceModal, setIsDeviceModal] = useState(false);
  const [isSelectedFarmModal, setIsSelectedFarmModal] = useState(false);
  const [createOrDelete, setCreateOrDelete] = useState(false);
  const [apiResponse, setApiResponse] = useState("ok");
  const validateRequired = (value) => !!value.length;

  console.log("selectedField :", selectedField);
  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(
      `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setUser(result.body);
        const filteredFields =
          result.body.fields?.filter(
            (field) =>
              field.deleted_at === undefined && field.isDevice === undefined
          ) || [];
        setField(filteredFields);
        setUserACL(result.body.ACL || []);
        console.log("filteredFields", filteredFields);
      })
      .catch((error) => console.log("error", error));
  }, [user_id, createOrDelete]);

  useEffect(() => {
    // Alanlara ilişkin izinleri al
    var updatedAcl = [];
    field.forEach((e) => {
      if (e.ACL) {
        e.ACL.forEach((ac) => {
          if (!ac.deleted_at) {
            const foundField = field.find((f) => f.id === ac.fieldid);

            if (foundField) {
              ac.name = foundField.name;
              ac.farmName = e.farmName;
            } else {
              // Eğer koşul sağlanmazsa yapılacak işlem
              // Buraya başka bir atama veya işlem ekleyebilirsiniz
            }

            console.log("find", foundField);
            // e.fieldname = field.find((f) => f.id == e.fieldid).name
            updatedAcl = [...updatedAcl, ac];
          } else {
          }
        });
      }
    });
    setAllAcl(updatedAcl);
  }, [field]);
  const FormatDateFunction = (timestamp) => {
    const date = new Date(timestamp); // Zaman damgasını kullanarak bir tarih oluştur
    const formattedDate = `${date.getDate()}.${
      date.getMonth() + 1
    }.${date.getFullYear()}`; // Tarihi istediğiniz formata dönüştür
    return formattedDate; // Konsola formatlanmış tarihi yazdır
  };
  const AttachRoleFunction = async (groupAttach) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var arr = {
      AC: {
        ownerid: user_id,
        ownermail: userMail,
        fieldid: groupAttach ? groupAttach : selectedFarmRow?.id,
        targetid: targetUser,
        targetmail: targetUser,
        role: selectedRole,
      },
    };
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(arr),
    };

    try {
      const response = await fetch(
        `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/field/${
          groupAttach ? groupAttach : selectedFarmRow?.id
        }`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Request failed!");
      }

      const result = await response.json();
      console.log(result);
      setCreateModalOpen(false);
      setLoading(false);
      setIsSelectedFarmModal(false);
      setCreateOrDelete((prev) => !prev);
    } catch (error) {
      console.log("Request error", error.message);
      alert("Failed Attach Role");
      setCreateModalOpen(false);
      setLoading(false);
    }
  };

  const AttachGroupRoleFunction = async () => {
    try {
      setLoading(true);

      // Tüm seçilen alanlar için asenkron olarak AttachRoleFunction fonksiyonunu çağırın
      for (let i = 0; i < allSelectedFields.length; i++) {
        const e = allSelectedFields[i];
        await AttachRoleFunction2(e);
      }
    } catch (error) {
      console.log("Request error", error.message);
      alert("Failed Attach Role");
    } finally {
      setCreateGroupModalOpen(false);
      setLoading(false);
      setCreateOrDelete((prev) => !prev);
    }
  };

  const AttachRoleFunction2 = async (groupAttach) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var arr = {
      AC: {
        ownerid: user_id,
        ownermail: userMail,
        fieldid: groupAttach.id,
        targetid: targetUser,
        targetmail: targetUser,
        role: selectedRole,
      },
    };
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(arr),
    };

    try {
      const response = await fetch(
        `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/field/${groupAttach.id}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Request failed!");
      }

      const result = await response.json();
      console.log(result);
    } catch (error) {
      console.log("Request error", error.message);
      throw error;
    }
  };

  const DeleteRoleFunction = (a) => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var arr = {
      AC: a,
    };
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: JSON.stringify(arr),
    };
    fetch(
      `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/field/${a.fieldid}`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((result, code) => {
        console.log(result);
        setDeleteLoading((prev) => false);
        setIsSelectedFarmModal(false);

        setCreateOrDelete((prev) => !prev);
        setIsDeviceModal((prev) => false);
      })
      .catch((error) => {
        console.log(" Request error", error.message);
        setApiResponse((prev) => "failed");
        setDeleteLoading((prev) => false);
        setIsSelectedFarmModal(false);

        setIsDeviceModal((prev) => false);
      });
  };
  const GetUserACL = () => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var arr = {
      ACL: userACL,
    };
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(arr),
    };
    fetch(
      `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/acl`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((result, code) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(" Request error", error.message);
      });
  };
  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid = validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors]
  );
  const columns = useMemo(
    () => [
      {
        accessorKey: "farmName",
        header: t("profileFarmName"),
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
        Cell: ({ cell }) => (
          <>
            <div className=" text-center p-1 rounded-lg text-textDark">
              {cell.row.original.farmName
                ? cell.row.original.farmName
                : "no farm"}
            </div>
          </>
        ),
      },
      {
        accessorKey: "name",
        header: t("profileFieldName"),
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        enableClickToCopy: true,
        size: 80,
      },
      {
        accessorKey: "devices",
        header: t("devices"),
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        enableEditing: false, //disable editing on this column
        size: 80,
        Cell: ({ cell }) => (
          <>
            <div className=" text-center p-1 rounded-lg text-textDark">
              {cell.row.original.devices && cell.row.original.devices.length > 0
                ? cell.row.original.devices.length
                : 0}
            </div>
          </>
        ),
      },
      {
        accessorKey: "created_at",
        header: t("profileCreated"),
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
        Cell: ({ cell }) => (
          <>
            <div className=" text-center p-1 rounded-lg text-textDark">
              {FormatDateFunction(cell.row.original.created_at)}
            </div>
          </>
        ),
      },
      // {
      //   accessorKey: "Actions",
      //   header: "Actions",
      //   size: 80,
      //   muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
      //     ...getCommonEditTextFieldProps(cell),
      //   }),
      // }
    ],
    [getCommonEditTextFieldProps]
  );
  const columns2 = useMemo(
    () => [
      {
        accessorKey: "name",
        header: t("profileFieldName"),
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        enableClickToCopy: true,
        size: 80,
      },
      {
        accessorKey: "ownermail",
        header: t("profileOwner"),
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        enableEditing: true, //disable editing on this column
        size: 80,
      },
      {
        accessorKey: "targetmail",
        header: t("profileTarget"),
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "role",
        header: t("profileRole"),
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
        Cell: ({ cell }) => (
          <>
            <div className="bg-blue-300 text-center p-1 rounded-lg text-white">
              {cell.row.original.role}
            </div>
          </>
        ),
      },
      // {
      //   accessorKey: "Actions",
      //   header: "Actions",
      //   size: 80,
      //   muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
      //     ...getCommonEditTextFieldProps(cell),
      //   }),
      // }
    ],
    [getCommonEditTextFieldProps]
  );
  const handleCreateNewRow = () => {
    alert("selam");
  };
  const CreateNewAccountModal = ({ open, columns, onClose, onSubmit }) => {
    const [values, setValues] = useState(() =>
      columns.reduce((acc, column) => {
        acc[column.accessorKey ?? ""] = "";
        return acc;
      }, {})
    );

    const handleSubmit = () => {
      //put your validation logic here
      onSubmit(values);
      onClose();
    };

    return (
      <Dialog open={open}>
        <DialogTitle textAlign="center">Attach Role</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
              sx={{
                width: "100%",
                minWidth: { xs: "300px", sm: "360px", md: "400px" },
                gap: "1.5rem",
              }}
            >
              <FormControl
                variant="outlined"
                sx={{ m: 0, minWidth: 120, width: "100%" }}
              >
                <InputLabel
                  id="select-field-label"
                  style={{ backgroundColor: "#ffffff", paddingRight: "10px" }}
                >
                  {t("setupSelectField")}
                </InputLabel>
                <Select
                  labelId="select-field-label"
                  id="select-field"
                  value={selectedField}
                  onChange={(e) => {
                    setSelectedField((prev) => e.target.value);
                    console.log("form içi selectedField:", e.target.value);
                  }}
                >
                  {field?.map((e) => (
                    <MenuItem key={Math.random()} value={e.id} className="p-3">
                      {e.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                variant="outlined"
                sx={{ m: 0, minWidth: 120, width: "100%" }}
              >
                <TextField
                  id="user-email"
                  label="Email"
                  type="email"
                  value={targetUser}
                  onChange={(e) => setTargerUser((prev) => e.target.value)}
                />
              </FormControl>

              <FormControl
                variant="outlined"
                sx={{ m: 0, minWidth: 120, width: "100%" }}
              >
                <InputLabel
                  id="select-role-label"
                  style={{ backgroundColor: "#ffffff", paddingRight: "10px" }}
                >
                  Select Role
                </InputLabel>
                <Select
                  labelId="select-role-label"
                  id="select-role"
                  value={selectedRole}
                  onChange={(e) => setSelectedRole((prev) => e.target.value)}
                >
                  <MenuItem key={"operator"} value={"operator"} className="p-3">
                    operator
                  </MenuItem>
                  <MenuItem key={"viewer"} value={"viewer"} className="p-3">
                    viewer
                  </MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </form>
        </DialogContent>
        <DialogActions sx={{ p: "1.25rem" }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={handleSubmit}
            variant="contained"
            disabled={targetUser & selectedField & selectedRole ? false : true}
          >
            Add Permission
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  function DeleteDeviceModal({ device }) {
    return (
      <div
        className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"
        id="modal-id"
      >
        <div className="absolute bg-black opacity-80 inset-0 z-0"></div>
        <div className="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
          <div className="">
            <div className="text-center p-5 flex-auto justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 -m-1 flex items-center text-red-500 mx-auto"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-16 h-16 flex items-center text-red-500 mx-auto"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
              <h2 className="text-xl font-bold py-4 ">Are you sure?</h2>
              <p className="text-sm text-gray-500 px-8">
                Do you really want to delete your Permission? This process
                cannot be undone.
              </p>
            </div>

            {deleteLoading ? (
              <div className="   w-full h-6 ">
                <div className="m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-700 border-4 h-6 w-6"></div>
              </div>
            ) : apiResponse !== "ok" ? (
              <p>Somethings went wrong.</p>
            ) : (
              <div className="p-3  mt-2 text-center space-x-4 md:block">
                <button
                  onClick={() => setIsDeviceModal(false)}
                  className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm  font-medium tracking-wider border text-black rounded-sm  hover:bg-gray-100"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    setDeleteLoading((prev) => true);
                    DeleteRoleFunction(device);
                  }}
                  className="mb-2 md:mb-0 bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-sm hover:shadow-lg hover:bg-red-600"
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  function selectedFarmSetACLFunction(data) {
    // selectedFarmRow?.ACL ?[...selectedFarmRow.ACL] : []
    var updatedAcl = [];
    data?.ACL?.forEach((e) => {
      if (!e.deleted_at) {
        updatedAcl.push(e);
      }
    });
    console.log("selectedFarmSetACLFunction data", data);
    console.log("selectedFarmSetACLFunction", updatedAcl);
    return updatedAcl;
  }

  return (
    <div className="max-sm:mt-4  mx-auto  sm:px-6 max-sm:p-5  max-sm:justify-center  lg:px-8 lg:pt-8 lg:pb-20 md:pt-16 ">
      <div className="w-full h-80 bg-bgoverview14 bg-slate-600 bg-blend-overlay  bg-top  bg-cover rounded-2xl f ">
        <div className=" pt-6 px-5 w-full flex flex-row justify-between ">
          <p className="text-base  text-white font-semibold ">
            {t("navProfile")}
          </p>
          <div className=" flex flex-row">
            <select
              className="block  mr-1 text-base  text-white font-semibold bg-white/0 "
              onChange={(e) => changeLanguageHandler(e.target.value)}
              defaultValue={i18n?.language}
            >
              <option value="en-EN">EN</option>
              <option value="tr-TR">TR</option>
            </select>
            {/* <div className="w-9 h-9 bg-white rounded-md text-2xl flex items-center justify-center shadow-miniMap">
              <ion-icon name="notifications-outline"></ion-icon>
            </div> */}
          </div>
        </div>
      </div>
      <div className="flex max-sm:flex  mx-10 max-sm:mx-6 py-6 px-5 -mt-14 flex-row justify-between    scroll   z-50 rounded-2xl  bg-white  bg-opacity-80 shadow-myShadow1 backdrop-blur-[20px] ">
        <div className="flex flex-row ">
          <div className="w-14 h-14 rounded-md bg-orange-400 mr-3 shadow-miniMap">
            <img src={People} className="w-full h-full rounded-md " />
          </div>
          <div className="flex flex-col ">
            <p className="text-textDark font-semibold w-full max-sm:w-52  text-base truncate overflow-hiddens h-auto">
              {userMail ? user?.name : "adım yok"}
            </p>
            <p className="text-textDark text-xs truncate flex flex-row gap-1">
              {userMail}
            </p>
            
          </div>
        </div>
      </div>

      <div className="flex flex-col bg-white p-6 mt-6 rounded-2xl">
        <p className="text-base text-textDark font-semibold mb-2">
          {t("profileRolesAndPermissions")}
        </p>
        {console.log("allACL:", allACL)}
        <MaterialReactTable
          columns={columns}
          data={[...field]}
          editingMode="modal" //default
          enableRowActions
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          enableGlobalFilter={false}
          enableHiding={false}
          enableColumnFilters={false}
          enablePagination={true}
          enableColumnActions={false}
          enableBottomToolbar={true}
          enableTopToolbar={true}
          enableGrouping={true}
          enableRowSelection
          selectAllMode="page"
          enableMultiRowSelection
          positionActionsColumn="last"
          muiTablePaperProps={{
            elevation: 0,
          }}
          initialState={{
            density: "compact",
            grouping: ["farmName"],
            expanded: true,
            pagination: { pageSize: 20, pageIndex: 0 },
            // columnPinning: {

            //   right: ["mrt-row-actions"],
            // },
          }}
          displayColumnDefOptions={{
            "mrt-row-actions": {
              size: 80, //if using layoutMode that is not 'semantic', the columns will not auto-size, so you need to set the size manually
              grow: false,
              display: "relative",
            },
          }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [10, 50, 200, 1000],
            showFirstButton: false,
            showLastButton: false,
          }}
          renderRowActions={({ row }) => [
            <Box
              key={Math.random()}
              sx={{ display: "flex", gap: "1rem" }}
              className=" items-center justify-center w-fit  "
            >
              <IconButton
                key={Math.random()}
                size="small"
                color="info"
                onClick={() => {
                  setIsSelectedFarmModal((prev) => true);
                  setSelectedFarmRow((prev) => row.original);
                  console.log("row.original", row.original);
                }}
              >
                <ion-icon key={Math.random()} name="options-outline"></ion-icon>
                <p key={Math.random()} className="ml-1 text-xs">
                  {t("profileManage")}{" "}
                </p>
              </IconButton>
            </Box>,
          ]}
          renderTopToolbar={({ table }) => {
            const handleContact = () => {
              var allSelectedField = [];
              table.getSelectedRowModel().flatRows.map((row) => {
                console.log("contact " + JSON.stringify(row.original));
                allSelectedField.push(row.original);
              });
              setAllSelectedFields((prev) => allSelectedField);
              setCreateGroupModalOpen(true);
              console.log(
                "table.getIsSomeRowsSelected()",
                table.getIsSomeRowsSelected()
              );
            };

            return (
              <Box
                key={Math.random()}
                sx={(theme) => ({
                  display: "flex",
                  gap: "0.5rem",
                  p: "8px",
                  justifyContent: "space-between",
                })}
              >
                <Box
                  key={Math.random()}
                  sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
                >
                  {/* import MRT sub-components */}
                </Box>
                <Box key={Math.random()}>
                  <Box
                    sx={{ display: "flex", gap: "0.5rem" }}
                    key={Math.random()}
                  >
                    <Button
                      key={Math.random()}
                      color="info"
                      disabled={!table.getIsSomeRowsSelected()}
                      onClick={handleContact}
                      variant="contained"
                    >
                      {t("profileAttachRole")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            );
          }}
        />
      </div>
      <button
              onClick={() => {
                navigate("/deleteMyAccount");
              }}
              className="w-full mt-6 mx-auto  border border-red-500 px-5  py-2 text-sm  text-red-500 rounded-sm "
            >
              Delete Account
            </button>
      {/* manage fields */}
      <Modal
        open={isSelectedFarmModal}
        onClose={() => setIsSelectedFarmModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className=" translate-y-1/2  w-[80%] mx-auto  max-sm:w-5/6 bg-white rounded-2xl  backdrop-blur-md  shadow-24 p-5  flex flex-col">
          <MaterialReactTable
            columns={columns2}
            data={selectedFarmSetACLFunction(selectedFarmRow)}
            editingMode="modal" //default
            enableRowActions
            enableFullScreenToggle={false}
            enableDensityToggle={false}
            enableGlobalFilter={false}
            enableHiding={false}
            enableColumnFilters={false}
            enablePagination={false}
            enableColumnActions={false}
            enableBottomToolbar={true}
            enableTopToolbar={false}
            enableGrouping={false}
            muiTablePaperProps={{
              elevation: 0,
            }}
            initialState={{
              pagination: { pageSize: 20, pageIndex: 0 },
              columnPinning: {
                left: ["mrt-row-expand", "mrt-row-select"],
                right: ["mrt-row-actions"],
              },
            }}
            muiTablePaginationProps={{
              rowsPerPageOptions: [10, 50, 200, 1000],
              showFirstButton: false,
              showLastButton: false,
            }}
            renderRowActions={({ row }) => [
              <Box
                sx={{ display: "flex", gap: "1rem" }}
                className=" items-center justify-center w-fit  "
              >
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => {
                    setIsDeviceModal((prev) => true);
                    setSelectedDeleteRow((prev) => row.original);
                  }}
                >
                  <ion-icon name="trash-outline"></ion-icon>
                  <p className="ml-1 text-xs">{t("delete")}</p>
                </IconButton>
              </Box>,
            ]}
            renderBottomToolbarCustomActions={({ table }) => (
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  p: "0.5rem",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  color="primary"
                  onClick={() => setCreateModalOpen(true)}
                  variant="contained"
                  sx={{
                    fontWeight: "600",
                    background:
                      "linear-gradient(146deg, #62CBF8 5.53%, #3157F5 91.6%)",
                  }}
                >
                  {t("profileAttachRole")}
                </Button>
              </Box>
            )}
          />
        </Box>
      </Modal>
      {/* attach orle */}
      <Modal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="mx-auto translate-y-1/2 w-1/3 max-sm:w-5/6 bg-white/90 rounded-2xl  backdrop-blur-md  shadow-24 p-5  flex flex-col">
          <form onSubmit={(e) => e.preventDefault()}>
            <p className="w-full p-2 text-center text-textDark text-lg">
              {t("profileAttachRole")}
            </p>
            <Stack
              sx={{
                width: "100%",
                minWidth: { xs: "300px", sm: "360px", md: "400px" },
                gap: "1.5rem",
              }}
            >
              <FormControl
                variant="outlined"
                sx={{ m: 0, minWidth: 120, width: "100%" }}
              >
                {/* <InputLabel
                  id="select-field-label"
                  style={{ paddingRight: "10px" }}
                >
                  {t("setupSelectField")}
                </InputLabel>
                <Select
                  labelId="select-field-label"
                  id="select-field"
                  sx={{
                    color: "rgb(69 74 115)",
                  }}
                  value={selectedFarmRow?.name}
                  onChange={(e) => {
                    setSelectedField((prev) => e.target.value);
                    console.log("form içi selectedField:", e.target.value);
                  }}
                  defaultValue={selectedFarmRow?.name}
                >
                  
                </Select> */}
                {/* <TextField 
                  value={selectedFarmRow?.name}
                  disabled
                  /> */}
              </FormControl>

              <FormControl
                variant="outlined"
                sx={{ m: 0, minWidth: 120, width: "100%" }}
              >
                <TextField
                  id="user-email"
                  label="Email"
                  type="email"
                  value={targetUser}
                  onChange={(e) => setTargerUser((prev) => e.target.value)}
                />
              </FormControl>

              <FormControl
                variant="outlined"
                sx={{ m: 0, minWidth: 120, width: "100%" }}
              >
                <InputLabel
                  id="select-role-label"
                  style={{ paddingRight: "10px" }}
                >
                  {t("profileSelectRole")}
                </InputLabel>
                <Select
                  labelId="select-role-label"
                  id="select-role"
                  value={selectedRole}
                  onChange={(e) => setSelectedRole((prev) => e.target.value)}
                >
                  <MenuItem key={"operator"} value={"operator"} className="p-3">
                    operator
                  </MenuItem>
                  <MenuItem key={"viewer"} value={"viewer"} className="p-3">
                    viewer
                  </MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <div className=" p-4 w-full flex flex-row gap-2 justify-end">
              <Button
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() => setCreateModalOpen(false)}
                variant="text"
                sx={{ fontWeight: "600", color: "rgb(69 74 115)" }}
              >
                {t("cancel")}
              </Button>
              <Button
                onClick={(e) => AttachRoleFunction()}
                variant="contained"
                disabled={targetUser && selectedRole ? false : true}
                sx={{
                  fontWeight: "600",
                  background: `${
                    (targetUser && selectedRole) || loading
                      ? "linear-gradient(146deg, #62CBF8 5.53%, #3157F5 91.6%)"
                      : "rgb(69 74 115)"
                  }`,
                  color: "#FFF !important",
                }}
              >
                {loading ? (
                  <div className=" flex flex-row flex-wrap">
                    <div className=" m-auto border-t-transparent border-solid animate-spin  rounded-full border-white border-4 h-5 w-5"></div>
                  </div>
                ) : (
                  t("profileAttachRole")
                )}
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
      {/* create group */}
      <Modal
        open={createGroupModalOpen}
        onClose={() => setCreateGroupModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="mx-auto translate-y-1/2 w-1/3 max-sm:w-5/6 bg-white/90 rounded-2xl  backdrop-blur-md  shadow-24 p-5  flex flex-col">
          <form onSubmit={(e) => e.preventDefault()}>
            <p className="w-full p-2 text-center text-textDark text-lg">
              {t("profileAttachRole")}
            </p>
            <Stack
              sx={{
                width: "100%",
                minWidth: { xs: "300px", sm: "360px", md: "400px" },
                gap: "1.5rem",
              }}
            >
              <FormControl
                variant="outlined"
                sx={{ m: 0, minWidth: 120, width: "100%" }}
              >
                {/* <InputLabel
                  id="select-field-label"
                  style={{ paddingRight: "10px" }}
                >
                  {t("setupSelectField")}
                </InputLabel>
                <Select
                  labelId="select-field-label"
                  id="select-field"
                  sx={{
                    color: "rgb(69 74 115)",
                  }}
                  value={selectedFarmRow?.name}
                  onChange={(e) => {
                    setSelectedField((prev) => e.target.value);
                    console.log("form içi selectedField:", e.target.value);
                  }}
                  defaultValue={selectedFarmRow?.name}
                >
                  
                </Select> */}
                {/* <TextField 
                  value={selectedFarmRow?.name}
                  disabled
                  /> */}
              </FormControl>

              <FormControl
                variant="outlined"
                sx={{ m: 0, minWidth: 120, width: "100%" }}
              >
                <TextField
                  id="user-email"
                  label="Email"
                  type="email"
                  value={targetUser}
                  onChange={(e) => setTargerUser((prev) => e.target.value)}
                />
              </FormControl>

              <FormControl
                variant="outlined"
                sx={{ m: 0, minWidth: 120, width: "100%" }}
              >
                <InputLabel
                  id="select-role-label"
                  style={{ paddingRight: "10px" }}
                >
                  {t("profileSelectRole")}
                </InputLabel>
                <Select
                  labelId="select-role-label"
                  id="select-role"
                  value={selectedRole}
                  onChange={(e) => setSelectedRole((prev) => e.target.value)}
                >
                  <MenuItem key={"operator"} value={"operator"} className="p-3">
                    operator
                  </MenuItem>
                  <MenuItem key={"viewer"} value={"viewer"} className="p-3">
                    viewer
                  </MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <div className=" p-4 w-full flex flex-row gap-2 justify-end">
              <Button
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() => setCreateGroupModalOpen(false)}
                variant="text"
                sx={{ fontWeight: "600", color: "rgb(69 74 115)" }}
              >
                {t("cancel")}
              </Button>
              <Button
                onClick={AttachGroupRoleFunction}
                variant="contained"
                disabled={targetUser && selectedRole ? false : true}
                sx={{
                  fontWeight: "600",
                  background: `${
                    (targetUser && selectedRole) || loading
                      ? "linear-gradient(146deg, #62CBF8 5.53%, #3157F5 91.6%)"
                      : "rgb(69 74 115)"
                  }`,
                  color: "#FFF !important",
                }}
              >
                {loading ? (
                  <div className=" flex flex-row flex-wrap">
                    <div className=" m-auto border-t-transparent border-solid animate-spin  rounded-full border-white border-4 h-5 w-5"></div>
                  </div>
                ) : (
                  t("profileAttachRole")
                )}
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
      {/* delet modal */}
      <Modal
        open={isDeviceModal}
        onClose={() => setIsDeviceModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="mx-auto translate-y-1/2 w-1/3 max-sm:w-5/6 bg-white/90 rounded-2xl  backdrop-blur-md  shadow-24 p-0  flex flex-col">
          <div className="" id="modal-id">
            <div className="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
              <div className="">
                <div className="text-center p-5 flex-auto justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 -m-1 flex items-center text-red-500 mx-auto"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-16 h-16 flex items-center text-red-500 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <h2 className="text-xl font-bold py-4 ">
                    {t("profileAreYouSure")}
                  </h2>
                  <p className="text-sm text-gray-500 px-8">
                    {t("profileWantDelete")}
                  </p>
                </div>

                {deleteLoading ? (
                  <div className="   w-full h-6 ">
                    <div className="m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-700 border-4 h-6 w-6"></div>
                  </div>
                ) : apiResponse !== "ok" ? (
                  <p>{t("profileWentWrong")}.</p>
                ) : (
                  <div className="p-3  mt-2 text-center space-x-4 md:block">
                    <button
                      onClick={() => setIsDeviceModal(false)}
                      className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm  font-medium tracking-wider border text-black rounded-sm  hover:bg-gray-100"
                    >
                      {t("cancel")}
                    </button>
                    <button
                      onClick={() => {
                        setDeleteLoading((prev) => true);
                        DeleteRoleFunction(selectedDeleteRow);
                      }}
                      className="mb-2 md:mb-0 bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-sm hover:shadow-lg hover:bg-red-600"
                    >
                      {t("delete")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {/* {isDeviceModal ? <DeleteDeviceModal device={selectedDeleteRow} /> : ""} */}
    </div>
  );
}

export default Profile;
