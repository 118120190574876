import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  Marker,
  TileLayer,
  Popup,
  Circle,
  Polygon,
  AttributionControl,
  Tooltip,
  useMapEvents,
  useMap,
  Polyline,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ReactWeather, { useOpenWeather } from "react-open-weather";
import SettingsIcon from "../assets/settingsIcon.svg";
import RulerIcon from "../assets/ruler.svg";
import WeatherCard from "../components/WeatherCard/WeatherCard";
import { useTranslation } from "react-i18next";
import FarmDevices from "../components/FarmDevices2";
// import FarmDevices from "../components/FarmDevices";
import Devices from "../components/Devices2";
import { Sheet } from "react-modal-sheet";
import DeviceOcakCard from "../components/DeviceOcakCard2";
import DeviceCard from "../components/DeviceCard";
import DeviceTofCard from "../components/DeviceTofCard";
import DeviceVanaCard from "../components/DeviceVanaCard";
import DevicePompaCard from "../components/DevicePompaCard";
import { BottomSheet } from "react-spring-bottom-sheet";

const markerIzciIcon = new L.Icon({
  iconUrl: require("../assets/softpinkmarker.png"),
  iconSize: [20, 20],
  iconAnchor: [10, 10],
  popupAnchor: [0, -10],
});
const markerOcakIcon = new L.Icon({
  iconUrl: require("../assets/softorangemarker.png"),
  iconSize: [20, 20],
  iconAnchor: [10, 10],
  popupAnchor: [0, -10],
});
const markerOptimal = new L.Icon({
  iconUrl: require("../assets/softwhitemarker.png"),
  iconSize: [20, 20],
  iconAnchor: [10, 10],
  popupAnchor: [0, -10],
});
const pop = new L.popup({});
///
function ZoomControl() {
  const map = useMap();

  const zoomIn = () => {
    map.setZoom(map.getZoom() + 1);
  };

  const zoomOut = () => {
    map.setZoom(map.getZoom() - 1);
  };

  return (
    <div className="">
      <div className=" flex flex-col items-center bg-white !border-none text-textDark text-base rounded-lg">
        <p className=" px-4 py-2 " title="Zoom in" onClick={zoomIn}>
          +
        </p>
        <p className=" px-4 py-2 " title="Zoom out" onClick={zoomOut}>
          -
        </p>
      </div>
    </div>
  );
}
const markerIcon = new L.Icon({
  iconUrl: require("../assets/softpinkmarker.png"),
  iconSize: [10, 10],
  iconAnchor: [5, 5],
  popupAnchor: [0, 10],
});
function MeasureDistance({
  isEnabled,
  toggleMeasure,
  isCompleted,
  toggleCompleted,
}) {
  const [points, setPoints] = useState([]);
  const [distances, setDistances] = useState([]);
  const [tempPoint, setTempPoint] = useState(null);
  const [totalDistance, setTotalDistance] = useState(0);
  const [area, setArea] = useState(0);

  const calculateDistance = (point1, point2) => {
    return L.latLng(point1)?.distanceTo(L.latLng(point2))
      ? L.latLng(point1)?.distanceTo(L.latLng(point2))
      : 0;
  };

  const calculateArea = (latlngs) => {
    console.log(
      "L.GeometryUtil.geodesicArea(latlngs)",
      L.GeometryUtil.geodesicArea(latlngs)
    );
    // let total = 0;
    // console.log(latlngs);
    // for (let i = 0, l = latlngs.length; i < l; i++) {
    //   let addX = latlngs[i].lng;
    //   let addY = latlngs[(i + 1) % l].lat;
    //   let subX = latlngs[(i + 1) % l].lng;
    //   let subY = latlngs[i].lat;
    //   total += (addX * addY * 0.000247105) - (subX * subY * 0.000247105);
    // }
    return L.GeometryUtil.geodesicArea(latlngs) / 10000;
  };

  const handleMarkerClick = () => {
    if (isEnabled) {
      setTempPoint(null);
      // toggleMeasure();
      // alert("marker")
      points.length > 2 && toggleCompleted(true);
      const newPoint = points[0];
      if (points.length >= 1) {
        const lastPoint = points[points.length - 1];
        const distance = calculateDistance(lastPoint, newPoint);
        setDistances([...distances, distance]);
        setTotalDistance(totalDistance + distance);
      }
      setPoints((prev) => [...points, newPoint]);
      console.log(points);
      if (points.length > 2) {
        const calculatedArea = calculateArea([...points, newPoint]);
        console.log("calculatedArea", calculatedArea);
        setArea((prev) => calculatedArea);
      }
    }
  };

  useMapEvents({
    click(e) {
      if (isEnabled && !isCompleted) {
        const newPoint = e.latlng;
        if (points.length >= 1) {
          const lastPoint = points[points.length - 1];
          const distance = calculateDistance(lastPoint, newPoint);
          setDistances([...distances, distance]);
          setTotalDistance(totalDistance + distance);
        }
        setPoints((prev) => [...points, newPoint]);
        console.log(points);
        if (points.length > 2) {
          const calculatedArea = calculateArea([...points, newPoint]);
          console.log("calculatedArea", calculatedArea);
          setArea((prev) => calculatedArea);
        }
      }
    },
    mousemove(e) {
      if (isEnabled && points.length > 0 && !isCompleted) {
        setTempPoint(e.latlng);
      }
    },
  });

  useEffect(() => {
    if (!isEnabled) {
      setPoints([]);
      setDistances([]);
      setTempPoint(null);
      setTotalDistance(0);
      setArea(0);
    }
  }, [isEnabled]);

  return (
    <div>
      <div
        after={"place point"}
        className={`${
          points.length <= 1 && isEnabled ? "after:flex" : "after:hidden"
        } flex flex-col items-center bg-white !border-none text-textDark text-base rounded-lg  justify-center after:content-[attr(after)] after:absolute after:bg-white after:px-2 after:h-10 after:text-center after:items-center after:w-max after:flex after:left-8 after:rounded-r-lg after:text-sm`}
      >
        <div className="w-10 p-1 h-10  items-center flex justify-center">
          <button
            onClick={() => {
              toggleMeasure();
              toggleCompleted(false);
            }}
          >
            <svg
              width="25"
              height="25"
              viewBox="0 0 800 800"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M633.333 407.717L665.153 375.897C710.607 330.442 733.333 307.716 733.333 279.475C733.333 251.235 710.607 228.508 665.153 183.056L616.943 134.846C571.493 89.393 548.767 66.6667 520.523 66.6667C492.283 66.6667 469.557 89.393 424.103 134.846L134.846 424.103C89.3931 469.557 66.6667 492.283 66.6667 520.523C66.6667 548.767 89.3931 571.493 134.846 616.943L183.056 665.153C228.508 710.607 251.235 733.333 279.475 733.333C307.716 733.333 330.442 710.607 375.897 665.153L541.05 500"
                stroke={isEnabled ? "#3b82f6" : "#454a73"}
                stroke-width="50"
                stroke-linecap="round"
              />
              <path
                d="M282.145 282.149L329.286 329.29"
                stroke={isEnabled ? "#3b82f6" : "#454a73"}
                stroke-width="50"
                stroke-linecap="round"
              />
              <path
                d="M423.567 140.727L470.707 187.867"
                stroke={isEnabled ? "#3b82f6" : "#454a73"}
                stroke-width="50"
                stroke-linecap="round"
              />
              <path
                d="M140.731 423.573L187.871 470.713"
                stroke={isEnabled ? "#3b82f6" : "#454a73"}
                stroke-width="50"
                stroke-linecap="round"
              />
              <path
                d="M211.442 352.86L282.153 423.57"
                stroke={isEnabled ? "#3b82f6" : "#454a73"}
                stroke-width="50"
                stroke-linecap="round"
              />
              <path
                d="M352.857 211.438L423.567 282.149"
                stroke={isEnabled ? "#3b82f6" : "#454a73"}
                stroke-width="50"
                stroke-linecap="round"
              />
            </svg>
          </button>
        </div>
      </div>
      {points.map((point, index) => (
        <Marker
          key={index}
          position={point}
          icon={L.divIcon({ className: "w-10 h-10 bg-blue-500 rounded-full" })}
          eventHandlers={{
            click: handleMarkerClick,
          }}
        >
          {index === points.length - 1 && index != 0 && (
            <Tooltip
              direction="top"
              permanent={true}
              className="!m-0 !p-0 !border-none !rounded-lg "
              interactive={true}
            >
              {isCompleted ? (
                <div className=" bg-white/80 m-0 px-3 py-2 text-sm text-textDark rounded-lg flex flex-col">
                  <div className="flex flex-row w-full justify-between items-center mb-1">
                    <p className="mr-16">Measure distance</p>
                    <button
                      className="  text-lg"
                      onClick={() => {
                        toggleMeasure();
                        toggleCompleted(false);
                      }}
                    >
                      <ion-icon name="close-outline"></ion-icon>
                    </button>
                  </div>
                  <div>Total distance: {totalDistance.toFixed(2)} m</div>
                  {index === points.length - 1 && index > 1 && (
                    <div>Total area: {Number(area).toFixed(1)} ha</div>
                  )}
                </div>
              ) : (
                <div className="bg-white/80 m-0 p-2 text-sm text-textDark rounded-lg">
                  <div>{distances[index - 1].toFixed(2)} m</div>
                  {index === points.length - 1 && index > 1 && (
                    <div>{Number(area).toFixed(1)} ha</div>
                  )}
                </div>
              )}
            </Tooltip>
          )}
        </Marker>
      ))}
      {points.length > 0 && tempPoint && (
        <Polyline positions={[...points, tempPoint]} dashArray="1, 5" />
      )}
      {points.length > 1 && <Polyline positions={points} dashArray="1, 5" />}
    </div>
  );
}
function ScaleControl() {
  const map = useMap();

  useEffect(() => {
    L.control.scale().addTo(map);
  }, [map]);

  return null;
}

{
  /* <Tooltip
            direction="top"
            permanent={true}
            className="!m-0 !p-0 !border-none !rounded-lg"
          >
            <div className="bg-white/80 m-0 p-2 text-sm text-textDark rounded-lg">
              {" "}
              {distances[index - 1].toFixed(2)} m
            </div>
          </Tooltip> */
}
//
function SelectedFarm2() {
  const { t, i18n } = useTranslation();
  const width = window.innerWidth;
  const isMobile = width < 768;
  const myRef = useRef(null);
  const mapRef = useRef(null);
  const sheetRef = useRef(null);
  const navigate = useNavigate();
  const { fieldname } = useParams();
  const location = useLocation();
  const [fieldTown, setFieldTown] = useState();
  // const [isOpen, setOpen] = useState(true);
  const [open, setOpen] = useState(true);

  const [firstzoom, setFirstZoom] = useState(false);
  const [expandOnDrag, setExpandOnDrag] = useState(true); // expandOnContentDrag için state

  const [initialSnap, setInitialSnap] = useState(1);
  const [mountPoint, setMountPoint] = useState(null);
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const [selectedPolygonId, setSelectedPolygonId] = useState(
    location?.state?.selectedfield?.id
  );
  const [selectedFieldData, setSelectedFieldData] = useState(
    location?.state?.selectedfield
  );
  const [zoomLevel, setZoomLevel] = useState(15);
  var fieldData = location.state.allfields && location.state.allfields;
  console.log("location", location);
  var position = [];
  var positionset =
    fieldData?.geometry?.length > 0 &&
    fieldData.geometry.map((e) => {
      position.push([e.lat, e.lng]);
    });
  const [isMeasuring, setIsMeasuring] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const toggleMeasure = () => {
    setIsMeasuring(!isMeasuring);
  };
  const toggleCompleted = (bool) => {
    setIsCompleted(bool);
  };

  useEffect(() => {
    if (sheetRef.current) {
      setMountPoint(sheetRef.current);
    }
  }, [sheetRef]);

  useEffect(() => {
    if (mapRef?.current) {
      const mapInstance = mapRef.current?.leafletElement;
      mapInstance?.invalidateSize();
    }
  }, [mapRef]);

  useEffect(() => {
    var navi = document.getElementById("mobilBody");
    if (navi.classList.contains("mobilSmallBody")) {
      return;
    } else {
      myRef.current.scrollIntoView({ behavior: "instant", inline: "start" });
    }
  }, []);

  useEffect(() => {
    const requestOptionsnom = {
      method: "GET",
    };
    fetch(
      `https://nominatim.openstreetmap.org/search?q=${location?.state?.selectedfield?.center?.lat},${location?.state?.selectedfield?.center?.lng}&addressdetails=1&format=json`,
      requestOptionsnom
    )
      .then((response) => response.json())
      .then((result) => {
        setFieldTown(
          `${result[0].address.town}, ${result[0].address.province} `
        );
      })
      .catch((err) => console.log("err : ", err));
  }, []);

  const { data, isLoading, errorMessage, locationLabel } = useOpenWeather({
    key: "649aab4a6e31bce8122c212ee9b978bf",
    lat: fieldData?.[0]?.center?.lat,
    lon: fieldData?.[0]?.center?.lng,
    lang: "tr",
    unit: "metric",
  });

  const slides = ["3", "4", "5"];

  const executeScroll = () => {
    myRef.current.scrollIntoView({ behavior: "smooth", inline: "start" });
  };

  const setGeometry = (geometry) => {
    var fieldposition = [];
    geometry?.map((e) => {
      fieldposition.push([e.lat, e.lng]);
    });
    return fieldposition;
  };

  const calculateAverageCenter = (farm) => {
    let totalLng = 0;
    let totalLat = 0;
    let centerCount = 0;

    farm.forEach((e) => {
      if (e.center && e.center.lng && e.center.lat) {
        totalLng += e.center.lng;
        totalLat += e.center.lat;
        centerCount++;
      }
    });

    if (centerCount === 0) {
      return null;
    }

    const averageLng = totalLng / centerCount;
    const averageLat = totalLat / centerCount;

    return [averageLat, averageLng];
  };

  const zoomToPolygon = (shape, id) => {
    sheetRef.current.snapTo(({ maxHeight }) => 200);

    const map = mapRef.current;
    if (map) {
      if (shape instanceof L.Polygon) {
        map.fitBounds(shape.getBounds());
        setSelectedPolygon((prev) => shape);
      setSelectedPolygonId((pre) => id);
      setInitialSnap((prev) => 1); // Sheet bileşenini alt limite kaydır
      } else if (shape instanceof L.Circle) {
        map.setView(shape.getLatLng(), map.getZoom());
        setSelectedPolygon((prev) => shape);
      setSelectedPolygonId((pre) => id);
      setInitialSnap((prev) => 1); // Sheet bileşenini alt limite kaydır
      }
      

      const selectedData = fieldData.find((data) => data.id === id);

      if (selectedData) {
        setSelectedFieldData((prev) => selectedData);
      }
    }
  };
  const zoomToFirstField = (type , shape, id) => {
    const map = mapRef.current;
    if (map) {
      if (type == "polygon") {
        map.fitBounds(shape);

      }else if(type == "circle"){
        map.setView(shape, map.getZoom());


      }
      // setSelectedPolygon(polygon);
      // setSelectedPolygonId((pre) => id);
      // setInitialSnap((prev) => 1); // Sheet bileşenini alt limite kaydı
      // alert(`initialSnap : ${initialSnap}`);
      // const selectedData = fieldData.find((data) => data.id === id);
      // if (selectedData) {
      //   setSelectedFieldData((prev) => selectedData);
      //   // alert(JSON.stringify(selectedData, null, 2));
      // }
      setFirstZoom(prev => true)
    }
  };

  const adjustPolygonView = () => {
    if (selectedPolygon && initialSnap === 1) {
      // alert(`initial snap2: ${initialSnap}`)
      const map = mapRef.current;
      const bounds = selectedPolygon.getBounds();
      const center = bounds.getCenter();
      const newCenter = [center.lat - 0.0017, center.lng]; // Slightly move the center up
      map.setView(newCenter, map.getZoom() - 1.3); // Zoom out slightly
    }
  };

  const adjustPolygonViewReset = () => {
    if (selectedPolygon) {
      const map = mapRef.current;
      const bounds = selectedPolygon.getBounds();
      const center = bounds.getCenter();
      const newCenter = [center.lat, center.lng]; // Slightly move the center up
      map.setView(newCenter, map.getZoom() + 1); // Zoom out slightly
    }
  };

  const handleSnap = (snapIndex) => {
    if (snapIndex === 0) {
      adjustPolygonView();
      setInitialSnap((pre) => 0);
    } else if (snapIndex === 1) {
      adjustPolygonViewReset();
      setInitialSnap((pre) => 1);
    }
  };

  const MapEvents = () => {
    useMapEvents({
      zoomend: (event) => {
        const zoom = event.target.getZoom();
        setZoomLevel(zoom);
      },
      click(e) {
        if (
          !e.originalEvent.target.closest(".leaflet-marker-icon") &&
          !e.originalEvent.target.closest(".leaflet-popup-content-wrapper") &&
          !e.originalEvent.target.closest(".leaflet-interactive")
        ) {
          setSelectedFieldData(false);
        }
      },
    });

    return null;
  };

  // useEffect(() => {
  //   if (initialSnap) {
  //     setIsTop((prev) => true);
  //     scrollRef.current.scrollTop = 0;
  //   } else {
  //     setIsTop((prev) => false);
  //     scrollRef.current.scrollTop = 2;
  //   }
  //   const map = mapRef.current;
  //   console.log(mapRef.current);

  //   if (map) {
  //     // Adjust dragging property based on initialSnap
  //     if (initialSnap === 0) {
  //       // map.dragging.disable(); // Disable dragging
  //     } else {
  //       map.dragging.enable(); // Enable dragging
  //     }
  //   }
  // }, [initialSnap]); // Effect depends on initialSnap

  const [isTop, setIsTop] = useState(true);
  const scrollRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      console.log("sheetref", sheetRef.current);

      if (scrollRef.current.scrollTop === 0) {
        !expandOnDrag && setExpandOnDrag((prev) => true);
      } else {
        expandOnDrag && setExpandOnDrag((prev) => false);
      }
    };

    const scrollElement = scrollRef.current;
    scrollElement && scrollElement.addEventListener("scroll", handleScroll);

    return () => {
      scrollElement &&
        scrollElement.removeEventListener("scroll", handleScroll);
    };
  }, [scrollRef?.current?.scrollTop]);
  return (
    <div
      id="section1"
      ref={myRef}
      className=" bg-white relative pl-4 py-4 max-sm:p-5 h-[93vh]  rounded-xl  flex max-sm:flex-col flex-row gap-3  mt-8 mx-10 max-sm:mx-0  max-sm:mt-0 max-sm:pt-0  max-sm:justify-center "
    >
      <div
        onClick={() => {
          var navi = document.getElementById("mobilBody");
          if (navi.classList.contains("mobilSmallBody")) {
            return;
          } else {
            executeScroll();
          }
        }}
        className="hidden fixed w-80 lg:mb-0 max-sm:w-9/12 mx-auto top-0 z-50 left-1/2  -translate-x-1/2 max-sm:flex max-sm:flex-row justify-between bg-white bg-opacity-80  lg:shadow-lg backdrop-blur-[0.4rem] rounded-2xl items-center h-24 mt-5 px-4 pl-8"
      >
        <div>
          <p className="text-base lg:text-base font-semibold max-sm:text-base text-textDark ">
            {selectedFieldData
              ? selectedFieldData.name
              : fieldData?.[0]?.farmName}
          </p>
          <p className="text-xs text-textLight -mt-1 ">
            {selectedFieldData ? fieldData?.[0]?.farmName : fieldTown}
          </p>
        </div>
        <div className="mr-3 flex items-center text-center text-textDark  text-2xl">
          <ion-icon name="notifications-outline"></ion-icon>
        </div>
      </div>
      <div className=" w-8/12 max-sm:w-full h-full relative flex max-sm:flex-col gap-3  ">
        <div className="w-full h-full max-sm:w-full max-sm:h-[100vh]">
          <div className="relative  w-full h-full max-sm:h-[100%] max-sm:w-[calc(100%+2.5rem)] max-sm:-ml-5  max-sm:rounded-none z-0 lg:shadow-none">
            <MapContainer
              center={calculateAverageCenter(fieldData)}
              getZoom={!firstzoom &&zoomToFirstField(
                location?.state?.selectedfield?.geometry_type,
                location?.state?.selectedfield?.geometry_type == "polygon" ?  location?.state?.selectedfield?.geometry : location?.state?.selectedfield?.center,
                location?.state?.selectedfield?.id
              )}
              zoom={15}
              zoomSnap={0.5}
              scrollWheelZoom={true}
              className="w-full max-sm:w-full h-full max-sm:h-full z-0 max-sm:rounded-none rounded-xl "
              dragging={true}
              zoomControl={false}
              attributionControl={false}
              ref={mapRef}
              zoomAnimation={true}
              fadeAnimation={true}
            >
              <TileLayer
                maxNativeZoom={18}
                maxZoom={25}
                url="https://mt1.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}"
              />

              <MapEvents />

              <div className="absolute z-[999] top-1/4  left-2     flex flex-col gap-4">
                <ZoomControl className="!w-full !h-full" />
                <MeasureDistance
                  isEnabled={isMeasuring}
                  toggleMeasure={toggleMeasure}
                  toggleCompleted={toggleCompleted}
                  isCompleted={isCompleted}
                />
              </div>

              {/* <ScaleControl /> */}
              <AttributionControl position="bottomright" prefix={"Leaflet"} />

              {fieldData.map((data) => {
                if (data.geometry_type === "polygon") {
                  return (
                    <Polygon
                      className=""
                      key={
                        data.id +
                        (selectedFieldData && selectedPolygonId === data.id
                          ? "-selected"
                          : "")
                      }
                      positions={setGeometry(data.geometry)}
                      color={
                        selectedFieldData && selectedPolygonId == data.id
                          ? "#4079F6"
                          : "#ffffff"
                      }
                      fillColor={
                        selectedFieldData && selectedPolygonId == data.id
                          ? "#4079F6"
                          : "#ffffff"
                      }
                      weight={
                        selectedFieldData && selectedPolygonId === data.id
                          ? 10
                          : 1
                      }
                      eventHandlers={{
                        click: (e) => {
                          zoomToPolygon(e.target, data.id);
                        },
                      }}
                    >
                      <Tooltip
                        direction="center"
                        permanent
                        className={` !bg-transparent !border-none !shadow-none `}
                      >
                        <p
                          className={`${
                            zoomLevel >= 16 ? "!opacity-100" : "!opacity-0"
                          } text-white transition-all flex flex-col items-center`}
                        >
                          <p>{data?.name}</p>
                          <p className="px-2 rounded-2xl bg-white/40">
                            {selectedPolygonId === data.id
                              ? (
                                  L.GeometryUtil.geodesicArea(data?.geometry) /
                                  10000
                                ).toFixed(1) + " ha"
                              : ""}{" "}
                          </p>
                        </p>
                        {/* <p className="text-white">{zoomLevel}</p> */}
                      </Tooltip>
                    </Polygon>
                  );
                } else {
                  return null;
                }
              })}
              {fieldData.map((data) => {
                return data.geometry_type === "circle" ? (
                  <Circle
                    key={data.id}
                    center={data.center}
                    radius={data.radius}
                    color="#ffffff"
                    fillColor="#ffffff"
                  />
                ) : null;
              })}
              {zoomLevel >= 17 &&
                fieldData.map((data) => {
                  return data.devices?.map((e) => {
                    return e?.coords ? (
                      <div className="!hidden">
                        <Marker
                          key={e.id}
                          position={[e.coords?.lat, e.coords?.lon]}
                          icon={markerOptimal}
                        >
                          <Popup
                            autoClose={false}
                            className="custom-popup"
                            // offset={L.point(180, 180)}
                          >
                            <div className="!relative flex flex-row -ml-12">
                              {e.type == "izci" && <DeviceCard data={e} />}
                              {e.type == "tof" && <DeviceTofCard data={e} />}
                              {e.type == "vana" && <DeviceVanaCard data={e} />}
                              {e.type == "pompa" && (
                                <DevicePompaCard data={e} />
                              )}
                              {e.type == "ocak" && <DeviceOcakCard data={e} />}
                              {e.type == "tof" && <DeviceTofCard data={e} />}
                            </div>
                          </Popup>
                        </Marker>
                      </div>
                    ) : null;
                  });
                })}
            </MapContainer>
          </div>
        </div>
        <div
          onClick={() => {
            var navi = document.getElementById("mobilBody");
            if (navi.classList.contains("mobilSmallBody")) {
              return;
            } else {
              executeScroll();
            }
          }}
          className="max-sm:hidden absolute w-80 lg:mb-0 max-sm:w-9/12 mx-auto top-0 z-50 left-1/2  -translate-x-1/2 flex max-sm:flex-row justify-between bg-white bg-opacity-80  lg:shadow-lg backdrop-blur-[0.4rem] rounded-2xl items-center h-24 mt-5 px-4 pl-8"
        >
          <div>
            <p className="text-base font-semibold  text-textDark ">
              {selectedFieldData
                ? selectedFieldData.name
                : fieldData?.[0]?.farmName}
            </p>
            <p className="text-xs text-textLight -mt-1 ">
              {selectedFieldData ? fieldData?.[0]?.farmName : fieldTown}
            </p>
          </div>
          <div className="mr-3 flex items-center text-center text-textDark  text-2xl">
            <ion-icon name="notifications-outline"></ion-icon>
          </div>
        </div>
      </div>

      <div ref={sheetRef} className="hidden max-sm:flex">
        
        <BottomSheet
          open={open}
          ref={sheetRef}
          defaultSnap={({ maxHeight }) => 200}
          snapPoints={({ maxHeight }) => [200, 500]}
          expandOnContentDrag={expandOnDrag} // Dinamik olarak belirleniyor
          blocking={false}
          scrollLocking={false}
          className="  !relative !z-[50] hidden max-sm:flex"
          id="sheetBody"
        >
          <div
                ref={scrollRef}
                className={`${
                  isTop ? "overflow-y-clip" : "overflow-y-scroll"
                } flex flex-col px-3 h-[29rem] overflow-y-scroll`}
              >
                <div className=" max-sm:w-full lg:absolute lg:right-0 lg:top-0 z-0 ">
                  <div className="w-full ">
                    <p className="px-2 leading-none text-base font-semibold  text-textDark">
                      Weather
                    </p>
                    <p className="px-2 text-xs pb-3 text-textLight">
                      Monitor current weather and forecast.
                    </p>
                    {fieldData?.map((e) => {
                      return e?.devices?.map((a) => {
                        return a?.type === "ocak" ? (
                          <DeviceOcakCard key={a.id} data={a} fieldData={e} />
                        ) : null;
                      });
                    })}
                    <WeatherCard
                      lat={fieldData[0]?.center?.lat}
                      lon={fieldData[0]?.center?.lng}
                      lang={i18n.language}
                    />
                  </div>
                  <div className="max-sm:hidden flex flex-col justify-center items-center h-[12rem] w-[90%] mx-auto mt-[4.5rem] rounded-2xl border-dashed border">
                    <div className="text-5xl text-textLight">
                      <ion-icon name="notifications-off-circle-outline"></ion-icon>
                    </div>
                    <p className="text-md text-textLight">{t("notNoNot")}</p>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div
                    id="myElement13"
                    className="myElement13 z-10  pb-4 transition-all duration-300 ease-in-out delay-75"
                  ></div>
                  <div>
                    {selectedFieldData ? (
                      <Devices data={selectedFieldData} />
                    ) : (
                      <FarmDevices data={fieldData} />
                    )}
                  </div>
                </div>
              </div>
        </BottomSheet>
      </div>
      <div className=" bg-none rounded-xl mt-6 px-3  w-1/3 max-sm:hidden h-[calc(100%-1.5rem)] overflow-y-scroll  scrollbar-thin scrollbar-thumb-slate-200 scrollbar-track-transparent ">
        <div className="bg-none  max-sm:!bg-opacity-80  max-sm:!backdrop-blur-[0.4rem]  !shadow-none !rounded-2xl ">
          <div className=" max-sm:w-full  lg:right-0 lg:top-0 z-0 ">
            <div className="w-full ">
              <p className=" leading-none text-base font-medium text-textDark">
                Weather
              </p>
              <p className=" text-sm pb-3 text-textLight">
                Monitor current weather and forecast.
              </p>
              {fieldData?.map((e) => {
                return e?.devices?.map((a) => {
                  return a?.type === "ocak" ? (
                    <DeviceOcakCard key={a.id} data={a} fieldData={e} />
                  ) : null;
                });
              })}
              <WeatherCard
                lat={fieldData[0]?.center?.lat}
                lon={fieldData[0]?.center?.lng}
                lang={i18n.language}
              />
            </div>
            {/* <div className="max-sm:hidden flex flex-col justify-center items-center h-[12rem] w-[90%] mx-auto mt-[4.5rem] rounded-2xl border-dashed border">
              <div className="text-5xl text-textLight">
                <ion-icon name="notifications-off-circle-outline"></ion-icon>
              </div>
              <p className="text-md text-textLight">{t("notNoNot")}</p>
            </div> */}
          </div>
          <div className="flex flex-col">
            <div
              id="myElement13"
              className="myElement13 z-10  pb-4 transition-all duration-300 ease-in-out delay-75"
            ></div>
            <div>
              {selectedFieldData ? (
                <Devices data={selectedFieldData} />
              ) : (
                <FarmDevices data={fieldData} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectedFarm2;
