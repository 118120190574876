import React, { useState, useContext, useEffect } from "react";
import SetupFieldImg from "../assets/setupFieldImg.png";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../events/Account";
import Scanner from "../components/BarcodeScanner";
import {
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  Popup,
  Polygon,
  useMapEvent,
  FeatureGroup,
  Circle,
  useMapEvents,
  AttributionControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { map } from "leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import { v4 as uuidv4 } from "uuid";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
const markerIcon = new L.Icon({
  iconUrl: require("../assets/marker-w.png"),
  iconSize: [40, 40],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});
var popup = new L.popup();

function SetupDevice() {
  const { t, i18n } = useTranslation();
  function LocationMarker() {
    const [position, setPosition] = useState(null);
    const map = useMapEvents({
      click(e) {
        map.locate();
        console.log(e);
        setQRLatLng({ lat: e.latlng.lat, lon: e.latlng.lng });
      },
    });

    return position === null ? null : (
      <Marker position={position}>
        <Popup>You are here</Popup>
      </Marker>
    );
  }
  const { apiKey, userMail, user_id } = useContext(AccountContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [userACL, setUserACL] = useState([]);
  const [userMobilDevices, setUserMobilDevices] = useState();
  const [ACLFields, setACLFields] = useState([]);
  const [step, setStep] = useState(1);
  const [field, setField] = useState([]);
  const [devices, setDevices] = useState([]);
  const [deviceId, setDeviceId] = useState();
  const [fieldPosition, setFieldPosition] = useState([]);
  const [selectedField, setSelectedField] = useState(0);
  const [selectedFieldGeometryType, setSelectedFieldGeometryType] = useState();

  const [QRData, setQRData] = useState();
  const [QRLatLng, setQRLatLng] = useState({ lat: 38, lon: 32 });
  const [QRstatus, setQRstatus] = useState();

  console.log("selectedField :", selectedField);
  useEffect(() => {
    if (window?.navigator?.userAgentData?.mobile) {
      setIsMobile(true);
    } else if (!window?.navigator?.userAgentData) {
      setIsMobile(true);
    }
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    readLocation();
    fetch(
      `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        console.log("dynamo userapi get: ", result);
        var filteredFields =
          result.body.fields &&
          result.body.fields.filter(
            (field) =>
              field.deleted_at == undefined && field.isDevice == undefined
          );
        setField((prev) => (filteredFields ? filteredFields : []));
        setDevices(result.body.devices);
        setUserACL((prev) => result.body.ACL);
        setUserMobilDevices((prev) => result.body.mobilDevices);
        GetUserACL(result.body.ACL);
      })
      .catch((error) => console.log("error", error));
  }, []);
  const GetUserACL = (acl) => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var arr = {
      ACL: acl,
    };
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(arr),
    };
    fetch(
      `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/acl`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((result, code) => {
        console.log(result);
        setACLFields(result.body);
      })
      .catch((error) => {
        console.log(" Request error", error.message);
      });
  };
  const readLocation = () => {
    var minAccuracy;

    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        function (position) {
          var latitude = position.coords.latitude;
          var longitude = position.coords.longitude;
          var accuracy = position.coords.accuracy;
          console.log("readlocation");
          setQRLatLng({
            lat: latitude ? latitude : 38,
            lon: longitude ? longitude : 32,
          });
          var capa = document.getElementById("capa");
          if (capa) {
            capa.innerHTML =
              "latitude: " +
              latitude +
              ", longitude: " +
              longitude +
              ", accuracy: " +
              accuracy.toFixed(2);
          }
        },
        function error(msg) {},
        { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true }
      );
    } else {
      alert("Geolocation API is not supported in your browser.");
    }

    return;
  };
  const qrScanned = (res) => {
    setQRData(res);
    readLocation();
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var arr = { deviceId: res };
    var requestOptionsGET = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(
      `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/device?deviceId=${res}`,
      requestOptionsGET
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Geçersiz Kare Kod");
        }
      })
      .then((result, code) => {
        console.log(
          "Device Request :",
          result.body,
          "ownerid. :",
          result.body.owner_id
        );
        if (result.body.owner_id !== "" && result.body.owner_id !== user_id) {
          throw new Error("Bu cihaza yetkiniz bulunmamakta");
        }
        setStep(3);
      })
      .catch((error) => {
        console.log("Device Request error", error.message);
        setQRstatus(error.message);
        setStep(66);
      });

    if (selectedField !== 0) {
      field.map((e) => {
        if (e.id == selectedField) {
          console.log("dayubue", e);
          if (e.geometry_type == "polygon") {
            e.geometry?.length > 0 &&
              e.geometry.map((e) => {
                var positionf = [];
                positionf.push([e.lat, e.lng]);
                setFieldPosition(positionf);
                setSelectedFieldGeometryType("polygon");
              });
          }
          if (e.geometry_type == "circle") {
            setFieldPosition({ center: e.center, radius: e.radius });
            setSelectedFieldGeometryType("circle");
          }
        }
      });
    }
  };
  const deviceSubmit = () => {
    console.log("selectedField deviceSubmitFunc:", selectedField);
    console.log("field deviceSubmitFunc:", field);
    console.log("aclfiled deviceSubmitFunc:", ACLFields);
    var selecedFieldAllData;
    var tempfield = field;
    if (selectedField.ACL) {
      selecedFieldAllData = selectedField;
    } else {
      tempfield.find((f, i) => {
        if (f.id == selectedField) {
          selecedFieldAllData = f;
        }
      });
    }

    if (selecedFieldAllData.ACL) {
      var userAC = selecedFieldAllData.ACL[0];

      var myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);

      var arr = {
        deviceId: QRData,
        coords: QRLatLng,
        user_id: user_id,
        AC: userAC,
      };

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(arr),
      };

      fetch(
        `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/device/acl`,
        requestOptions
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Geçersiz Kare Kod");
          }
        })
        .then((result, code) => {
          console.log(
            "Device Request :",
            result.body,
            "ownerid. :",
            result.body.owner_id
          );
          if (
            result.body.owner_id &&
            result.body.owner_id !== "" &&
            result.body.owner_id !== userAC.ownerid
          ) {
            throw new Error("Bu cihaza yetkiniz bulunmamakta");
          }
          setStep(4);
          setTimeout(() => navigate("/"), 3000);
          setLoading(false);
        })
        .catch((error) => {
          console.log("Device Request error", error.message);
          setQRstatus(error.message);
          setStep(66);
          setLoading(false);
        });
      console.log("allfield after operation :", allfield);
    } else {
      var allfield = [];
      var alldevice = [];
      allfield = field;
      alldevice = devices;
      // postField(allfield);
      if (alldevice) {
        alldevice.push(QRData);
      } else {
        alldevice = [QRData];
      }
      console.log("bu slecetde fielddır: ", selectedField);
      if (selectedField == 0) {
        var current = Date.now();
        allfield.push({
          id: uuidv4(),
          devices: [QRData],
          name: QRData.slice(-4),
          zoom: 18,
          created_at: current,
          //
        });
      } else {
        allfield.find((f, i) => {
          if (f.id == selectedField) {
            console.log("find method :", f, "index : ", i);
            if (allfield[i].devices) {
              allfield[i].devices.push(QRData);
            } else {
              allfield[i].devices = [QRData];
            }
          }
        });
      }
      var myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);

      var arr = {
        deviceId: QRData,
        coords: QRLatLng,
        user_id: user_id,
        fields: allfield,
        devices: alldevice,
        mobilDevices: userMobilDevices,
      };

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(arr),
      };

      fetch(
        `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/device`,
        requestOptions
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Geçersiz Kare Kod");
          }
        })
        .then((result, code) => {
          console.log(
            "Device Request :",
            result.body,
            "ownerid. :",
            result.body.owner_id
          );
          if (
            result.body.owner_id &&
            result.body.owner_id !== "" &&
            result.body.owner_id !== user_id
          ) {
            throw new Error("Bu cihaza yetkiniz bulunmamakta");
          }
          setStep(4);
          setTimeout(() => navigate("/"), 3000);
          setLoading(false);
        })
        .catch((error) => {
          console.log("Device Request error", error.message);
          setQRstatus(error.message);
          setStep(66);
          setLoading(false);
        });
      console.log("allfield after operation :", allfield);

      function postField(allfield) {
        var arr = { fields: allfield };

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(arr),
        };
        console.log(arr);
        fetch(
          `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/field`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
            setStep(4);
            setTimeout(() => navigate("/"), 3000);
          })
          .catch((error) => console.log("error", error));
      }
    }
  };
  function selectFieldFunc(e) {
    setSelectedField(e);
    setLoading(false);
  }
  return (
    <div className="relative max-sm:z-[51] h-screen bg-white  flex items-center justify-center overflow-clip ml-4 max-sm:pl-0">
      {step == 1 && (
        <div className="flex flex-col justify-center items-center  gap-1">
          <div className="flex flex-col justify-center items-center ">
            <img src={SetupFieldImg} className="w-20" />
            <p className="text-2xl text-textDark max-sm:w-11/12 text-center pt-3 mb-4 ">
              {t("setupWhichField")}
            </p>
          </div>
          {/* <select
            className='bg-zinc-200 w-60 h-10 text-lg p-2 '
            onChange={(e) => {
              setLoading(true);
              selectFieldFunc(e.target.value);
             
            }}
          >
            <option value={0} className='p-3'>
              tarla seçiniz
            </option>
            {field?.map((e) => {
              return e.isDevice ? (
                ''
              ) : (
                <option key={e.id} value={e.id} className='p-3'>
                  {e.name}
                </option>
              );
            })}
          </select> */}
          <FormControl
            variant="outlined"
            sx={{ m: 1, minWidth: 120, width: "20rem" }}
          >
            <InputLabel
              id="demo-simple-select-standard-label"
              sx={{ backgroundColor: "#ffffff", paddingRight: "10px" }}
            >
              {t("setupSelectField")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              onChange={(e) => setSelectedField((prev) => e.target.value)}
              label="Age"
            >
              {field?.map((e) => {
                return (
                  <MenuItem key={e.id} value={e.id} className="p-3">
                    {e.name}
                  </MenuItem>
                );
              })}

              {ACLFields?.map((e) => {
                console.log("acl selam", e);
                {
                  return (
                    e.role != "viewer" && (
                      <MenuItem key={e.id} value={e} className="p-3">
                        {e.name}
                      </MenuItem>
                    )
                  );
                }
              })}
              <p
                className=" text-blue-500 rounded-sm p-2 w-60 mx-auto  text-center  hover:cursor-pointer transition-all"
                onClick={() => navigate("/setupField")}
              >
                {t("setupCreateNewField")} +
              </p>
            </Select>
          </FormControl>

          <button
            className="group max-sm:ml-auto  max-sm:mr-10   ml-96 disabled:"
            disabled={selectedField == 0}
            onClick={() => {
              setStep(2);
              readLocation();
            }}
          >
            {loading == true ? (
              <div className="  bg-opacity-5 ">
                <div className="m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-700 border-4 h-6 w-6"></div>
              </div>
            ) : (
              <p className="text-xl p-3 group-disabled:text-gray-500  text-green-600 hover:cursor-pointer transition-all">
                {t("next")}
              </p>
            )}
          </button>

          <div
            className="  mt-44  "
            onClick={() => {
              setSelectedField(0);
              readLocation();
              setStep(2);
            }}
          >
            <p className=" text-gray-500 p-3 hover:bg-slate-100  hover:cursor-pointer transition-all">
              {t("setupContinueWithout")}
            </p>
          </div>
        </div>
      )}
      {step == 2 &&
        (isMobile ? (
          <div className=" flex  flex-col justify-center items-center h-screen w-full bg-white overflow-clip">
            <div className="flex flex-col justify-center items-center h-screen  w-full overflow-clip">
              <div
                className={` h-screen w-full overflow-hidden ${
                  QRData ? "" : ""
                }`}
              >
                <Scanner onResult={(res) => qrScanned(res)} />
              </div>
              <button
                className="text-sm m-5 bg-blue-700 text-white rounded-md p-3 fixed bottom-20"
                onClick={() => setIsMobile(false)}
              >
                {t("setupSetUpDeviceNo")}
              </button>
              <button
                id="capa"
                className="text-sm m-5 bg-white  fixed top-5"
                onClick={() => readLocation()}
              ></button>
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-col justify-center items-center bg-white h-screen gap-6">
              <div className="flex flex-col justify-center items-center ">
                <img src={SetupFieldImg} className="w-20" />
                <p className="text-xl w-[40rem] max-sm:w-80 text-center mb-7">
                  {t("setupUseMobileApp")}
                </p>
                <p className="text-3xl w-[40rem] max-sm:w-80 text-center">
                  {t("setupContinueDeviceNo")}
                </p>
              </div>
              <input
                value={deviceId}
                onChange={(event) => setQRData(event.target.value)}
                className="max-sm:w-10/12 w-80 border-b-black h-16 bg-neutral-200 px-3 py-2 text-xl leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                type="text"
                placeholder={`XXXYYY111222333`}
              />
              <div
                className="ml-auto   p-3"
                onClick={() => {
                  readLocation();
                  setStep(3);
                }}
              >
                <p className="text-xl text-green-600">{t("next")}</p>
                <></>
              </div>
            </div>
          </>
        ))}
      {step == 3 && (
        <div className="flex  flex-col justify-center items-center bg-white w-full ">
          <div
            className={`flex flex-col justify-center items-center ${
              isMobile ? "pt-0" : "pt-16"
            } h-screen bg-oran w-full`}
          >
            <MapContainer
              center={QRLatLng}
              zoom={18}
              className="w-full h-full  z-50"
              scrollWheelZoom={isMobile ? false : true}
              dragging={isMobile ? false : true}
              zoomControl={false}
              attributionControl={false}
            >
              <TileLayer url="https://mt1.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}" />

              <AttributionControl position="bottomright" prefix={"Leaflet"} />
              {selectedFieldGeometryType == "polygon" && (
                <Polygon positions={fieldPosition} />
              )}
              {selectedFieldGeometryType == "circle" && (
                <Circle
                  center={fieldPosition?.center}
                  radius={fieldPosition?.radius}
                />
              )}
              <Marker position={QRLatLng} icon={markerIcon}>
                <Popup autoClose={false}>Suyabakan</Popup>
              </Marker>
              <ResetCenterView selectPosition={QRLatLng} />
              <LocationMarker />
            </MapContainer>

            <div className="absolute flex flex-row justify-center items-center z-50 h-24 pt-8  top-0  py-2 w-full text-center bg-white ">
              <p className="text-2xl mr-3 text-textDark">
                <ion-icon name="color-wand-outline"></ion-icon>
              </p>
              <p className="text-md text-textLight">
                {t("setupMarkLocation ")}
              </p>
            </div>

            <button
              disabled={loading}
              className="absolute z-50   bottom-14 max-sm:bottom-24 py-2 px-14 bg-white"
              onClick={() => {
                deviceSubmit();
                setLoading(true);
              }}
            >
              {loading == true ? (
                <div className="  bg-opacity-5 ">
                  <div className="m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-700 border-4 h-6 w-6"></div>
                </div>
              ) : (
                <p className="text-xl text-green-600  ">{t("next")}</p>
              )}
            </button>
            <div
              className="absolute z-50  text-2xl bottom-14 -mr-64 max-sm:bottom-24  w-11 h-11 text-center content-center pt-1  rounded-full bg-white"
              onClick={() => readLocation()}
            >
              <ion-icon name="locate-outline"></ion-icon>
            </div>
          </div>
        </div>
      )}
      {step == 4 && (
        <div className="flex  flex-col justify-center items-center bg-white  ">
          <div className="flex flex-col justify-center items-center  h-screen  w-full ">
            <div className="absolute translate-x-20 -translate-y-80 z-0 w-[40rem] h-[40rem] rounded-full bg-slate-100 " />
            <img src={SetupFieldImg} className="w-64 mb-4 z-10" />
            <p className="text-textDark text-3xl font-semibold flex flex-row">
              <div className=" text-xl text-lime-500 ">
                <ion-icon name="sparkles"></ion-icon>
              </div>
              {t("setupDeviceSuccess")}
            </p>
            <div className=" px-10 flex flex-row justify-center items-center">
              <p className="text-lg p-2 text-textLight  rounded-sm">
                {t("setupDeviceAdded")}
              </p>
            </div>
          </div>
        </div>
      )}
      {step == 20 && (
        <div className="flex  flex-col justify-center items-center bg-white  ">
          <div className="flex flex-col justify-center items-center  h-screen  w-full">
            <img src={SetupFieldImg} className="w-20 mb-4" />
            <p className="text-2xl bg-green-700 p-12 text-textLight rounded-sm">
              {t("setupDeviceAdded")} ;)
            </p>
          </div>
        </div>
      )}
      {step == 66 && (
        <div className="flex  flex-col justify-center items-center bg-white  ">
          <div className="flex flex-col justify-center items-center  h-screen  w-full">
            {/* <p className="pt-3">{QRstatus} </p> */}
            <div onClick={() => setStep(2)} className="text-5xl text-textDark">
              <ion-icon name="arrow-undo-circle-outline"></ion-icon>
            </div>
            <button
              className="text-2xl  p-3 text-textLight rounded-sm"
              onClick={() => setStep(2)}
            >
              {t("setupTryAgain")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SetupDevice;
function ResetCenterView(props) {
  const { selectPosition } = props;
  const map = useMap();

  if (selectPosition) {
    map.setView(
      L.latLng(selectPosition?.lat, selectPosition?.lon),
      map.getZoom(),
      {
        animate: true,
      }
    );
  }

  return null;
}
