import React, { useEffect, useRef, useState } from "react";
import ReactEcharts from "echarts-for-react";
import Drop from "../assets/drop.svg";
import Temp from "../assets/temp.svg";
import Particle from "../assets/particle.svg";
import { useParams, useLocation } from "react-router-dom";
import ReactWeather, { useOpenWeather } from "react-open-weather";
import SelectedIzci from "../components/SelectedIzci";
import SelectedOcak from "../components/SelectedOcak";
import SelectedVana from "../components/SelectedVana";
import FieldCard from "../components/FieldCard";

function SelectedDevice() {
  useEffect(() => {
    executeScroll();
  }, []);
  const { deviceid } = useParams();
  const location = useLocation();
  // console.log('seleceddevice : ', deviceid, 'locaiton :', location);
  const [fieldData, setFieldData] = useState(location.state && location.state);
  const [full, setfull] = useState(false);
  const [lastMeasureDate, setLastMeasureDate] = useState();
  const [lastData, setLastData] = useState();
  const [fieldTown, setFieldTown] = useState();

  

  const myRef = useRef(null);
  const executeScroll = () => {
    myRef.current.scrollIntoView({ behavior: "instant", inline: "start" });
  };
  console.log("fieldData:", fieldData);
  return (
    <div ref={myRef} className=" p-5">
      {fieldData.isDevice ? (
        FieldCard
      ) : fieldData.type == "izci" ? (
        <SelectedIzci />
      ) : fieldData.type == "vana" ? (
        <SelectedVana />
      ) : (
        <SelectedOcak />
      )}
    </div>
  );
}

export default SelectedDevice;
function StackedLine(rawdatas, names) {
  let option = {
    // title: {
    //   show: false,
    //   text: ' asd',
    //   display: 'none',
    // },

    tooltip: {
      trigger: "axis",
    },
    legend: {
      type: "scroll",
      top: "0%",
    },
    toolbox: {
      show: true,
      feature: {
        dataView: { show: false, readOnly: false },
        saveAsImage: {},
      },
      top: "12%",
      z: 100000,
    },
    grid: [
      {
        left: "3%",
        right: "5%",
        bottom: "8%",
        top: "10%",
        height: "75%",
        containLabel: true,
      },
    ],
    axisLine: {
      show: false,
    },
    xAxis: {
      type: "time",
    },
    yAxis: {
      splitLine: { show: false },
      splitArea: {
        show: true,
      },
      axisLine: { show: true },
      type: "value",
      max: function (value) {
        return value.max + 10;
      },
      min: function (value) {
        return value.min - 0;
      },
    },
    dataZoom: [
      {
        type: "slider",
        show: true,
        brushSelect: false,
        handleStyle: {
          borderColor: "rgba(0, 0, 0, 1)",
        },
        handleIcon: "roundRect",
        handleSize: "61%",
      },
    ],
    series: [],
    seriesCnt: "6",
    backgroundColor: "rgba(255,255,255,1)",
    titleColor: "#000000",
    subtitleColor: "#505050",
    textColorShow: false,
    textColor: "#333",
    markTextColor: "#ffffff",
    color: [
      "#0015FF",
      "#9900FF",
      "#00BBFF",
      "#FF8400",
      "#FF00BF",
      "#FFF700",
      "#00E0A5",
      "#9983A7",
      "#CC0000",
    ],
    borderColor: "#ccc",
    borderWidth: "0",
    visualMapColor: ["#8a7ca8", "#e098c7", "#cceffa"],
    legendTextColor: "#000000",
    kColor: "#e098c7",
    kColor0: "transparent",
    kBorderColor: "#e098c7",
    kBorderColor0: "#8fd3e8",
    kBorderWidth: "2",
    lineWidth: "1",
    symbolSize: "0",
    symbol: "emptyRoundRect",
    symbolBorderWidth: "0",
    lineSmooth: false,
    graphLineWidth: 1,
    graphLineColor: "#aaaaaa",
    mapLabelColor: "#000",
    mapLabelColorE: "#ffffff",
    mapBorderColor: "#444",
    mapBorderColorE: "#444",
    mapBorderWidth: 0.5,
    mapBorderWidthE: 1,
    mapAreaColor: "#eee",
    mapAreaColorE: "#e098c7",
    axes: [
      {
        type: "all",
        name: "通用坐标轴",
        axisLineShow: true,
        axisLineColor: "#000000",
        axisTickShow: false,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#000000",
        splitLineShow: false,
        splitLineColor: ["#eeeeee", "#333333"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
      },
      {
        type: "category",
        name: "类目坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: false,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "value",
        name: "数值坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "log",
        name: "对数坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "time",
        name: "时间坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
    ],
    axisSeperateSetting: false,
    toolboxColor: "#4100ff",
    toolboxEmphasisColor: "#000000",
    tooltipAxisColor: "#0013ff",
    tooltipAxisWidth: "3",
    timelineLineColor: "#8fd3e8",
    timelineLineWidth: 1,
    timelineItemColor: "#ff0000",
    timelineItemColorE: "#8fd3e8",
    timelineCheckColor: "#8fd3e8",
    timelineCheckBorderColor: "#8a7ca8",
    timelineItemBorderWidth: 1,
    timelineControlColor: "#8fd3e8",
    timelineControlBorderColor: "#8fd3e8",
    timelineControlBorderWidth: 0.5,
    timelineLabelColor: "#8fd3e8",
    datazoomBackgroundColor: "rgba(0,0,0,0)",
    datazoomDataColor: "rgba(255,255,255,0.3)",
    datazoomFillColor: "rgba(167,183,204,0.4)",
    datazoomHandleColor: "#a7b7cc",
    datazoomHandleWidth: "100",
    datazoomLabelColor: "#333",
  };
  rawdatas.map((e, index) => {
    option.series.push({
      name: names[index],
      symbol: "roundRect",
      type: "line",
      data: e,
      lineStyle: {
        width: 1,
      },
    });
  });
  return <ReactEcharts option={option} className=" z-10" />;
}
