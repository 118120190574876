import React, { useEffect, useState, useContext } from "react";
import ReactEcharts from "echarts-for-react";
import { useNavigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import OcakPanel from "../assets/ocakpanel.png";
import { useTranslation } from "react-i18next";
import { AccountContext } from "../events/Account";
import Trendline from "../assets/trendline.png";
import { useLocation } from 'react-router-dom';
import * as echarts from "echarts";
import { AppContext } from "../pages/Layout";

function DeviceOcakCard({ data, selectedDevice }) {
  const { t, i18n } = useTranslation();
  const { apiKey, user_id } = useContext(AccountContext);
  const {
    userApp,
    setUserApp,
    farmsApp,
    setFarmsApp,
    userOcakApp,
    setUserOcakApp,
    userVanaApp,
    setUserVanaApp,
    farmNameApp,
    setFarmNameApp,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [lastMeasure, setLastMeasure] = useState();
  const [tsdata, settsdata] = useState();
  const [loading, setLoading] = useState(true);
  const [connection, setConnection] = useState(3);
  const [newdata, setnewdata] = useState(false);
  const tempcurrent = [
    { mois: 67, temp: 29, cm: "05" },
    { mois: 66, temp: 29, cm: "15" },
    { mois: 56, temp: 25, cm: "25" },
    { mois: 50, temp: 24, cm: "35" },
    { mois: 49, temp: 23, cm: "45" },
    { mois: 48, temp: 23, cm: "55" },
    // { mois: 47, temp: 23, cm: '65' },
    // { mois: 45, temp: 23, cm: '75' },
    // { mois: 40, temp: 22, cm: '85' },
  ];
  useEffect(() => {
    console.log("id :::", data.id);
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var requestOptionsGET = {
      method: "GET",
      headers: myHeaders,
    };

    const fetchData = () => {
      const fetch1 = ()=> fetch(
        `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${data.id}&type=ocak&timeResolution=7d`
      )
        .then((response) => response.json())
        .then((result) => {
          const obj1 = result.body.map((e) => JSON.parse(e));
          console.log("ocak objilk:", obj1, userOcakApp);
          settsdata(obj1);

          //find device from userOcakApp array and add tsdata with 7d key if userOcakApp array includes device
          if (userOcakApp && userOcakApp.length > 0) {
            var index = userOcakApp.findIndex((x) => x.id === data.id);
            console.log("index", index);
            var temp = userOcakApp;
            if (index !== -1) {
              temp[index].tsdata7d = obj1;
              setUserOcakApp(prev => temp);
            }
          }
        })
        .catch((error) => {
          console.log("ocak error", error);
        });

      const fetch2 = () => fetch(
        `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${data.id}&type=ocak&timeResolution=last`
      )
        .then((response) => response.json())
        .then((result) => {
          const obj2 = result.body.map((e) => JSON.parse(e));
          console.log("ocak lastdata:", obj2);
          setLastMeasure(obj2);
          setLoading(false);
        })
        .catch((error) => {
          console.log("ocak error", error);
          setLoading(false);
        });

      //if userOcakApp defined and found device in userOcakApp array and have tsdata7d, set tsdata with tsdata7d and dont call fetch1
      var index = userOcakApp ? userOcakApp.findIndex((x) => x.id === data.id) : -1;
      console.log("index", index);
      console.log("userOcakApp", userOcakApp);
      
      if (index !== -1 && userOcakApp &&  userOcakApp[index].tsdata7d) {
        console.log("ocak tsdata7d contextten alındı ");
        settsdata(userOcakApp[index].tsdata7d);
        setLoading(false);
      } else {
        console.log("ocak tsdatası fetch ile alındı");
        fetch1()
      }
      fetch2();
    };

    // fetchData fonksiyonunu çağır
    if (connection == 3) {
      fetchData();
    }

    // WebSocket bağlantısını oluşturun
    var socket = new WebSocket(
      `wss://zh906rwmy8.execute-api.eu-west-1.amazonaws.com/production/?user_id=${user_id}&devices=[${data.id}]`
    );

    // Bağlantı başarıyla kurulduğunda
    socket.addEventListener("open", (event) => {
      console.log("ocak WebSocket bağlantısı başarıyla kuruldu.", event);
    });
    const pongInterval = setInterval(() => {
      socket.send("ping");
    }, 30000); // Her 30 saniyede bir
    // Mesaj alındığında
    socket.addEventListener("message", (event) => {
      setnewdata((prev) => true);
      console.log("ocak WebSocketten gelen mesaj:", event);
      var dat = JSON.parse(event.data);
      console.log("ocak dat dat dat 1:", dat);
      var parseddata = { ...dat.data };
      console.log("ocak dat dat dat:", parseddata);
      console.log("ocak tsdata", tsdata);

      settsdata((prev) => [parseddata, ...prev]);
    });

    // Bağlantı kapandığında
    socket.addEventListener("close", (event) => {
      console.log("ocak WebSocket bağlantısı kapatıldı.", event);
      clearInterval(pongInterval);

      setTimeout(() => {
        setConnection((prev) => !prev);
      }, 5000);
    });

    // Hata oluştuğunda
    socket.addEventListener("error", (event) => {
      console.error("ocak WebSocket hatası:", event);
    });
    console.log("ocak websocet kapalı triggered", socket.readyState);
  }, [connection]);
  function formatDate(date) {
    var mdate = new Date(Number(date));
    // console.log("date",mdate);
    mdate.setMinutes(mdate.getMinutes());
    // return `       ${mdate.getHours()}:${
    //   mdate.getMinutes() < 10 ? `0${mdate.getMinutes()}` : mdate.getMinutes()
    // }
    //  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
    // `;
    return `${timeDifference(mdate)}
     
    `;
  }
  function timeDifference(previous) {
    var current = new Date().getTime();
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + ` ${t("minutes")} ${t("ago")}`;
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + ` ${t("hours")} ${t("ago")}`;
    } else if (elapsed < msPerMonth) {
      return " " + Math.round(elapsed / msPerDay) + ` ${t("days")} ${t("ago")}`;
    } else if (elapsed < msPerYear) {
      return (
        " " + Math.round(elapsed / msPerMonth) + ` ${t("months")} ${t("ago")}`
      );
    } else {
      return (
        " " + Math.round(elapsed / msPerYear) + ` ${t("years")} ${t("ago")}`
      );
    }
  }
  const navigateFunction = (id) => {
    var pathname = window.location.pathname;
    var sonIndis = pathname.lastIndexOf("/"); // Son "/" işaretinin indisini buluyoruz
    var yeniDizge = pathname.substring(sonIndis + 1); // Substring metodu ile istenen kısmı alıyoruz
    if (yeniDizge !== id) {
      navigate(`${id}`, {
        state: { ...data, tsdata },
      });
    }
  };

  const location = useLocation();
  var path = location.pathname

  //graph
  function yaz(attrname) {
    var time = [];
    var value = [];
    var lasttime;

    tsdata?.map((data, index) => {
      if (attrname.includes("izci_sf")) {
        var calc;
        if (
          Number(data[attrname]) < 400000000 ||
          Number(data[attrname]) > 500000000
        ) {
          return;
        } else {
          calc = (500000000 - data[attrname]) / 1000000;
        }
        var mdate = new Date(data.time);
        mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
        value.push([mdate, Number(calc).toFixed(2)]);

        lasttime = Date.parse(data.time);
      } else if (index < 24 * 4) {
        var mdate = new Date(data.time);
        mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
        value.push([mdate, Number(data[attrname]).toFixed(2)]);

        lasttime = Date.parse(data.time);
      }
    });


    return value;
  }

  const DrawStackedAT = () => {
    var graphData1 = yaz(`ocak_air_temp`);
    return StackedLine([graphData1], [`ocak_air_temp}`]);
  };
  const DrawStackedAM = () => {
    var graphData1 = yaz(`ocak_air_moisture`);
    return StackedLine([graphData1], [`ocak_air_moisture}`]);
  };

  return (
    <div className={`${path.includes(data.id.slice(-4)) || path.length <3 ||path.includes("/devices") ?  "border-none": "border-b"} hover:cursor-pointer min-w-[20rem] max-w-[24rem]  max-sm:border-slate-300 max-h-72  w-full   max-sm:p-0  max-sm:w-full max-sm:mb-4`} >
      {loading ? (
        <div className=" w-full h-32 flex max-sm:flex-col flex-row gap-4">
          <div className="w-full h-32">
            <SkeletonTheme baseColor="#ededed" highlightColor="#f8f8f8">
              <Skeleton width={"100%"} height={"100%"} />
            </SkeletonTheme>
          </div>
        </div>
      ) : (
        <>
          <div
            className="h-32 w-full  flex flex-row flex-wrap max-sm:bg-transparent bg-white/0  px-3 py-3 rounded-2xl "
            onClick={() => {
              navigateFunction(`${data.id.slice(-4)}`, {
                state: { ...data, tsdata },
              });
            }}
          >
            <div className="w-6/12 ">
              
                <p className="text-base font-semibold text-textDark">
                  {data.id.slice(-4)}
                </p>
            
              <div className="flex flex-row  gap-2  items-center">
                <img src={OcakPanel} className="h-16 shadow-miniMap" />

                <div>
                  <p className=" flex flex-col text-xs text-textLight">
                    {t("fieldsLastMeasurement")}
                    <p className="">
                      {tsdata?.[0]?.received_date
                        ? formatDate(tsdata?.[0]?.received_date)
                        : formatDate(lastMeasure?.[0]?.received_date)}
                    </p>
                  </p>
                </div>
              </div>
            </div>
            <div className="w-6/12 pr-3 flex flex-col justify-center gap-0  text-xs">
              <div className="flex flex-row justify-around text-textLight gap-5">
                {/* <p>Wind</p> */}
                <p>{t("humidity")}</p>
                <p>{t("temp")}</p>
              </div>

              <div className="flex flex-row justify-around gap-6 px-1 font-semibold text-textDark text-base">
                {/* <p className='flex flex-row gap-1'>
                        5 <p className='text-sm  self-center '> km/h</p>
                      </p> */}
                <p className="flex flex-row gap-1 ">
                  {tsdata?.[0]?.received_date
                    ? Number(tsdata?.[0]?.ocak_air_moisture) >99 ? "--" : Number(tsdata?.[0]?.ocak_air_moisture)
                    : Number(lastMeasure?.[0]?.ocak_air_moisture) >99 ? "--" : Number(lastMeasure?.[0]?.ocak_air_moisture)}
                  <p className="text-xs  self-center "> %</p>
                </p>
                <p className="flex flex-row gap-1 ">
                  {tsdata?.[0]?.received_date
                    ? Number(tsdata?.[0]?.ocak_air_temp) == 202 ? "--" : Number(tsdata?.[0]?.ocak_air_temp)
                    : Number(lastMeasure?.[0]?.ocak_air_temp)== 202 ? "--" : Number(lastMeasure?.[0]?.ocak_air_temp)}
                  <p className="text-xs  self-center "> °</p>
                </p>
              </div>
              <div className="flex flex-row justify-evenly font-semibold text-textDark text-sm   gap-4">
              <div className="w-20 h-6">{DrawStackedAM()}</div>
              <div className="w-20 h-6">{DrawStackedAT()}</div>

              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default DeviceOcakCard;


function StackedLine(rawdatas, names) {
  let option = {
    // title: {
    //   show: false,
    //   text: ' asd',
    //   display: 'none',
    // },

    tooltip: {
      show: false,
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    legend: {
      show: false,
      type: "plain",
      top: "0%",
      padding: [
        0, // up
        0, // right
        0, // down
        45, // left
      ],
      icon: "roundRect",
    },
    toolbox: {
      show: false,
      feature: {
        dataView: { show: false, readOnly: false },
        saveAsImage: {},
      },
      top: "12%",
      z: 100000,
    },
    grid: [
      {
        left: "0%",
        right: "0%",
        bottom: "0%",
        top: "3%",
        height: "97%",
        containLabel: false,
      },
    ],
    axisLine: {
      show: false,
    },
    xAxis: {
      show: false,
      type: "time",
      boundaryGap: false,
    },
    yAxis: {
      splitLine: { show: false },
      splitArea: {
        show: false,
      },
      axisLine: { show: false },
      show: false,
      type: "value",
      max: function (value) {
        return (value.max + 0.1).toFixed(1);
      },
      min: function (value) {
        return (value.min - 0).toFixed(0);
      },
      axisTick: {
        inside: false,
      },
      axisLabel: {
        inside: false,
        formatter: "{value}\n",
      },
    },
    dataZoom: [
      {
        type: "inside",
        disabled: true,
        throttle: 50,
        show: false,
        brushSelect: false,
        handleStyle: {
          borderColor: "rgba(0, 0, 0, 1)",
        },
        handleIcon: "roundRect",
        handleSize: "61%",
      },
    ],
    series: [],
    seriesCnt: "6",
    backgroundColor: "rgba(255,255,255,0)",
    titleColor: "#000000",
    subtitleColor: "#505050",
    textColorShow: false,
    textColor: "#333",
    markTextColor: "#ffffff",
    color: [
      "#3b82f6",
      "#454a73",
      "#94d2bd",
      "#ca6702",
      "#bb3e03",
      "#ae2012",
      "#9b2226",
      "#0a9396",
      "#454a73",
    ],
    borderColor: "#ccc",
    borderWidth: "0",
    visualMapColor: ["#8a7ca8", "#e098c7", "#cceffa"],
    legendTextColor: "#000000",
    kColor: "#e098c7",
    kColor0: "transparent",
    kBorderColor: "#e098c7",
    kBorderColor0: "#8fd3e8",
    kBorderWidth: "2",
    lineWidth: "1",
    symbolSize: "0",
    symbol: "emptyRoundRect",
    symbolBorderWidth: "0",
    lineSmooth: false,
    graphLineWidth: 1,
    graphLineColor: "#aaaaaa",
    mapLabelColor: "#000",
    mapLabelColorE: "#ffffff",
    mapBorderColor: "#444",
    mapBorderColorE: "#444",
    mapBorderWidth: 0.5,
    mapBorderWidthE: 1,
    mapAreaColor: "#eee",
    mapAreaColorE: "#e098c7",
    axes: [
      {
        type: "all",
        name: "通用坐标轴",
        axisLineShow: true,
        axisLineColor: "#000000",
        axisTickShow: false,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#000000",
        splitLineShow: false,
        splitLineColor: ["#eeeeee", "#333333"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
      },
      {
        type: "category",
        name: "类目坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: false,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "value",
        name: "数值坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "log",
        name: "对数坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "time",
        name: "时间坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
    ],
    axisSeperateSetting: false,
    toolboxColor: "#4100ff",
    toolboxEmphasisColor: "#000000",
    tooltipAxisColor: "#0013ff",
    tooltipAxisWidth: "3",
    timelineLineColor: "#8fd3e8",
    timelineLineWidth: 1,
    timelineItemColor: "#ff0000",
    timelineItemColorE: "#8fd3e8",
    timelineCheckColor: "#8fd3e8",
    timelineCheckBorderColor: "#8a7ca8",
    timelineItemBorderWidth: 1,
    timelineControlColor: "#8fd3e8",
    timelineControlBorderColor: "#8fd3e8",
    timelineControlBorderWidth: 0.5,
    timelineLabelColor: "#8fd3e8",
    datazoomBackgroundColor: "rgba(0,0,0,0)",
    datazoomDataColor: "rgba(255,255,255,0.3)",
    datazoomFillColor: "rgba(167,183,204,0.4)",
    datazoomHandleColor: "#a7b7cc",
    datazoomHandleWidth: "100",
    datazoomLabelColor: "#333",
  };
  function areafunc(index) {
    if (names.length < 4) {
      console.log("index", index);
      option.series.lineStyle = { width: 0 };
      return {
        opacity: 0.5,
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: option.color[index],
          },
          {
            offset: 1,
            color: "rgb(255, 255, 255,0)",
          },
        ]),
      };
    } else {
      return {
        opacity: 0.0,
      };
    }
  }
  function linefunc(params) {
    if (names.length == 1) {
      return 1;
    } else {
      return 2;
    }
  }
  function linecolorfunc(index) {
    if (names.length == 1) {
      return 1;
    } else {
      return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: option.color[index],
        },
        {
          offset: 1,
          color: "rgb(255, 255, 255,0)",
        },
      ]);
    }
  }
  rawdatas.map((e, index) => {
    option.series.push({
      name: names[index],
      showSymbol: false,
      type: "line",
      silent: true,
      smooth: true,
      data: e,
      lineStyle: {
        width: linefunc(),
        // color: linecolorfunc(index),
        opacity: 0.5,
      },
      emphasis: {
        disabled: true,
        focus: "none",
      },
      areaStyle: areafunc(index),
    });
  });
  return (
    <ReactEcharts
      option={option}
      className=" !h-full w-full z-10"
      opts={{ renderer: "canvas" }}
      lazyUpdate={true}
    />
  );
}
