import React, { useContext, useState, useEffect } from "react";
import { AccountContext } from "../events/Account";
import FieldCard from "./FieldCard";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DeviceCard from "./DeviceCard";
import NoDrawn from "../assets/nodrawn.svg";
import { Link } from "react-router-dom";
import "./Fields.css";
import { useTranslation } from "react-i18next";
import FarmCard from "./FarmCard";
import { AppContext } from "../pages/Layout";

function Farms() {
  const { t, i18n } = useTranslation();
  const { apiKey, user_id } = useContext(AccountContext);
  const {
    userApp,
    setUserApp,
    farmsApp,
    setFarmsApp,
    userOcakApp,
    setUserOcakApp,
    userVanaApp,
    setUserVanaApp,
    farmNameApp,
    setFarmNameApp,
    userIzciApp,
    setUserIzciApp,
  } = useContext(AppContext);
  const [farms, setFarms] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("apikey", apiKey);
        myHeaders.append("userid", user_id);

        const requestOptionsGET = {
          method: "GET",
          headers: myHeaders,
        };

        var userData;
        var activeFields;
        // Kullanıcı verilerini alma
        if (userApp !== "") {
          userData = userApp;
          console.log("userData FarmsJS", userData);

          activeFields = userData.fields.filter(
            (field) => field.deleted_at === undefined
          );
        } else {
          const userDataResponse = await fetch(
            `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}`,
            requestOptionsGET
          );
          userData = await userDataResponse.json();
          activeFields = userData.body.fields.filter(
            (field) => field.deleted_at === undefined
          );
        }

        // Kullanıcı ACL'sini ayarlama işlemi için

        // Kullanıcıya ait ocak cihazlarını alma
        var allDevices = [];
        if (userOcakApp && userIzciApp && userVanaApp) {
          console.log("userOcakApp FarmsJSDevices", userOcakApp);
          console.log("userIzciApp FarmsJSDevices", userIzciApp);
          console.log("userVanaApp FarmsJSDevices", userVanaApp);

          allDevices = [
            ...(userOcakApp ?? []),
            ...(userIzciApp ?? []),
            ...(userVanaApp ?? []),
          ];
          console.log("allDevices FarmsJSDevices", allDevices);
        } else {
          const userOcakDeviceResponse = await fetch(
            `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/userOcakDevice`,
            requestOptionsGET
          );
          const userOcakDeviceData = await userOcakDeviceResponse.json();

          // Kullanıcıya ait izci cihazlarını alma
          const userIzciDeviceResponse = await fetch(
            `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/userIzciDevice`,
            requestOptionsGET
          );
          const userIzciDeviceData = await userIzciDeviceResponse.json();

          // Tüm cihazları birleştirme işlemi
          allDevices = [
            ...userOcakDeviceData?.body,
            ...userIzciDeviceData?.body,
          ];
        }

        // Tüm alanlarda cihazları güncelleme işlemi
        console.log("activeFields samira", activeFields);
        console.log("allDevices samira", allDevices);
        const updatedFields = activeFields.map((field) => {
          if (field.devices && field.devices[0] && !field.devices[0].id) {
            field.devices = field.devices.map((deviceId) =>
              allDevices.find((device) => {
                if (device.id === deviceId) {
                  device.fieldName = field.name;
                  return device;
                }
              })
            );
          }
          return field;
        });
        console.log("FARMS.js UseEffect:", updatedFields);
        GetUserFarm(updatedFields);
        // setFields(updatedFields);
        setLoading((prev) => false);
      } catch (error) {
        console.log("error", error);
        setLoading((prev) => false);

        // Hata durumunda kullanıcıya bilgilendirme yapılabilir
      }
    };

    fetchData();
  }, [apiKey, user_id]);

  const GetUserFarm = (allfield) => {
    const Farms = {};

    // Veriyi "farmName" özelliğine göre grupla
    allfield.forEach((item) => {
      if (item.farmName) {
        if (!Farms[item.farmName]) {
          Farms[item.farmName] = [item];
        } else {
          Farms[item.farmName].push(item);
        }
      }
    });
    console.log("Farms", Farms);
    setFarms((prev) => Farms);
  };

  return (
    <SkeletonTheme baseColor="#d9d9d9" highlightColor="#fff">
      <div className=" max-sm:bg-[#f8f9fa]">
        <div
          id="myElement13"
          className={` myElement13 max-sm:sticky  top-0 w-screen lg:w-full lg:bg-none -ml-5 px-5 z-[60] max-sm:bg-[#f8f9fa] max-sm:pt-10 pt-6 pb-4 transition-all duration-300  ease-in-out delay-75`}
        >
          <p className="text-base text-textDark font-semibold ">{t("farms")}</p>
          <div id="bigselam">
            <p className="text-textLight text-xs selam" id="selam">
              {t("monitorFarms")}
            </p>
          </div>
        </div>

        {loading ? (
          <div className=" w-full flex max-sm:flex-col flex-row gap-4 ">
            <div className="max-sm:w-full full h-40 ">
              <Skeleton width={"100%"} height={"100%"} />
            </div>
          </div>
        ) : (
          <div className="flex flex-row  h-auto gap-8 lg:gap-x-12  max-sm:justify-center m flex-wrap">
            {/* {farms
            ? farms?.map((e) => {
                return <FarmCard data={e} />;
              })
            : ""} */}
            {Object.keys(farms).length > 0 ? Object.keys(farms).map((farm) => {
              return <FarmCard key={Math.random()} farm={farms[farm]} />;
            }) :  <div className="w-full mt-2 h-40 border-2 border-dashed p-0 flex flex-col rounded-lg   z-0 items-center justify-center ">
            <p className="text-base font-semibold text-textDark">
              {t("fieldsNoDrawn")}
            </p>
            <p className="text-xs font-normal  text-center text-textLight">
              {t("fieldsDefineField")}
            </p>
          </div>}
          </div>
        )}
       

        {/* <p className=' text-4xl pt-10 text-black font-mediumpt-3 '>Devices</p>
      <div className='flex flex-row  h-auto gap-8 max-sm:justify-center max-sm:mb-28 flex-wrap mb-10'>
        {fields?.map((e) => {
          return e.isDevice ? <FieldCard key={e.id} data={e} /> : '';
        })}
      </div> */}
      </div>
    </SkeletonTheme>
  );
}

export default Farms;
