import React, { useEffect, useRef, useState } from "react";
import ReactEcharts from "echarts-for-react";
import Drop from "../assets/drop.svg";
import Temp from "../assets/temp.svg";
import Particle from "../assets/particle.svg";
import { useParams, useLocation } from "react-router-dom";
import ReactWeather, { useOpenWeather } from "react-open-weather";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DeviceVanaCard from "./DeviceVanaCard2";
import SettingsIcon from "../assets/settingsIcon.svg";
import WeatherCard from "./WeatherCard/WeatherCard";
import { useTranslation } from "react-i18next";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import * as echarts from "echarts";

function SelectedIzci() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    executeScroll();
  }, []);
  const { deviceid } = useParams();
  const location = useLocation();
  // console.log('seleceddevice : ', deviceid, 'locaiton :', location);
  const [fieldData, setFieldData] = useState(location.state && location.state);
  const [full, setfull] = useState(false);
  const [lastMeasureDate, setLastMeasureDate] = useState();
  const [lastData, setLastData] = useState();
  const [fieldTown, setFieldTown] = useState();
  const [filteredtsdata, setfilteredtsdata] = useState([]);
  const [filterSelect, setFilterSelect] = useState("1m");
  const [chunkSelect, setChunkSelect] = useState("h");
  var position = [];

  const { data, isLoading, errorMessage, locationLabel } = useOpenWeather({
    key: "649aab4a6e31bce8122c212ee9b978bf",
    // lat: 36.924754,
    // lon: 30.749551,
    lat: fieldData?.coords?.lat,
    lon: fieldData?.coords?.lon,
    lang: "en",
    unit: "metric", // values are (metric, standard, imperial)
  });
  // useEffect(() => {
  //   const requestOptionsnom = {
  //     method: "GET",
  //   };
  //   fetch(
  //     `https://nominatim.openstreetmap.org/search?q=${fieldData?.coords?.lat},${fieldData?.coords?.lon}&addressdetails=1&format=json`,
  //     requestOptionsnom
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log(("coord nomi:", result));
  //       setFieldTown(result[0].address.town);
  //     })
  //     .catch((err) => console.log("err : ", err));
  // }, []);

  // useEffect(() => {
  //   console.log("fieldData", fieldData);

  //   const fetchData = async () => {
  //     try {
  //       const response1 = await fetch(
  //         `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${fieldData.id}&type=vanalast&izcilength=${fieldData.izci_length}&detail=nodetail`,
  //         {
  //           method: "GET",
  //         }
  //       );
  //       const result1 = await response1.json();
  //       var parsedlastdata = JSON.parse(result1.body);
  //       setLastData(parsedlastdata);
  //       var templasttime = new Date(parsedlastdata.time);
  //       setLastMeasureDate(formatDate(templasttime));

  //       const response2 = await fetch(
  //         `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${fieldData.id}&type=vana&created_at=${fieldData.created_at}`,
  //         {
  //           method: "GET",
  //         }
  //       );
  //       const result2 = await response2.json();
  //       var obj = [];
  //       result2.body.map((e) => {
  //         obj.push(JSON.parse(e));
  //       });
  //       var tempfielddata = fieldData;
  //       tempfielddata.tsdata = obj;
  //       fieldData.tsdata = obj;
  //       setFieldData((prev) => tempfielddata);
  //       setfull(true);
  //       setfilteredtsdata((prev) => obj);
  //       console.log("objobj", tempfielddata);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  //   fetchData();
  //   FilterTimeFunction("1m", "h");
  // }, []);
  useEffect(() => {
    fetch(
      `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${fieldData.id}&type=last&izcilength=${fieldData.izci_length}&detail=nodetail`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        var parsedlastdata = JSON.parse(result.body);
        // console.log('lastdata parse :', parsedlastdata);
        setLastData(parsedlastdata);
        var templasttime = new Date(parsedlastdata.time);

        setLastMeasureDate(formatDate(templasttime));
      })
      .catch((err) => {
        // console.log('lasdatafetcherr : ', err);
      });
    fetch(
      `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${fieldData.id}&type=vana&created_at=${fieldData.created_at}`,

      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("izci ts", result);
        var asd = JSON.stringify(result.body);
        var obj = [];
        result.body.map((e) => {
          obj.push(JSON.parse(e));
        });
        // console.log('parse all:', obj[0]['sm5']);
        fieldData.tsdata = obj;
        setfilteredtsdata((prev) => obj);
      })
      .then(() => {
        setfull(true);
        FilterTimeFunction("1m", "h");
      })
      .catch((err) => {
        // console.log('devicecard err :', err);
      });
  }, []);

  function formatDate(date) {
    var mdate = new Date(date);
    mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
    return `       ${mdate.getHours()}:${
      mdate.getMinutes() < 10 ? `0${mdate.getMinutes()}` : mdate.getMinutes()
    }
     ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
    `;
  }
  function yaz(attrname) {
    var time = [];
    var value = [];
    var lasttime;
    console.log(attrname.includes("izci_sf"));
    filteredtsdata?.map((data, index) => {
      if (attrname.includes("izci_sf")) {
        var calc;
        if (
          Number(data[attrname]) < 400000000 ||
          Number(data[attrname]) > 500000000
        ) {
          return;
        } else {
          calc = (500000000 - data[attrname]) / 1000000;
        }
        var mdate = new Date(data.time);
        mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
        value.push([mdate, Number(calc).toFixed(2)]);

        lasttime = Date.parse(data.time);
      } else {
        var mdate = new Date(data.time);
        mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
        value.push([mdate, Number(data[attrname]).toFixed(2)]);

        lasttime = Date.parse(data.time);
      }
    });
    return value;
  }

  const DrawStackedTSSM = (data) => {
    
        var graphData1 = yaz("izci_sensor_data_in");
        var graphData2 = yaz("izci_sensor_data_out");
        return StackedLine([graphData1, graphData2], ["in", "out"]);
    
  };
  const DrawStackedTSST = (data) => {
    switch (fieldData.izci_length) {
      case "60":
        var graphData1 = yaz("izci_st_05");
        var graphData2 = yaz("izci_st_15");
        var graphData3 = yaz("izci_st_25");
        var graphData4 = yaz("izci_st_35");
        var graphData5 = yaz("izci_st_45");
        var graphData6 = yaz("izci_st_55");
        return StackedLine(
          [
            graphData1,
            graphData2,
            graphData3,
            graphData4,
            graphData5,
            graphData6,
          ],
          ["st05", "st15", "st25", "st35", "st45", "st55"]
        );
      case "90":
        var graphData1 = yaz("st5");
        var graphData2 = yaz("st15");
        var graphData3 = yaz("st25");
        var graphData4 = yaz("st35");
        var graphData5 = yaz("st45");
        var graphData6 = yaz("st55");
        var graphData7 = yaz("st65");
        var graphData8 = yaz("st75");
        var graphData9 = yaz("st85");
        return StackedLine(
          [
            graphData1,
            graphData2,
            graphData3,
            graphData4,
            graphData5,
            graphData6,
            graphData7,
            graphData8,
            graphData9,
          ],
          [
            "st05",
            "st15",
            "st25",
            "st35",
            "st45",
            "st55",
            "st65",
            "st75",
            "st85",
          ]
        );
      default:
        var graphData1 = yaz("st5");
        var graphData2 = yaz("st15");
        var graphData3 = yaz("st25");
        return StackedLine(
          [graphData1, graphData2, graphData3],
          ["st05", "st15", "st25"]
        );
    }
  };
  const DrawStackedTSAT = (data) => {
    var graphData1 = yaz("izci_at");
    return StackedLine([graphData1], ["at"]);
  };

  const myRef = useRef(null);
  const executeScroll = () => {
    myRef.current.scrollIntoView({ behavior: "smooth", inline: "start" });
  };
  const calculateAverage = (data, keys) => {
    const sum = {};
    keys.forEach((key) => (sum[key] = 0));

    data.forEach((item) => {
      keys.forEach((key) => {
        if (!isNaN(parseFloat(item[key]))) {
          sum[key] += parseFloat(item[key]);
        }
      });
    });

    const averages = {};
    keys.forEach(
      (key) => (averages[key] = (sum[key] / data.length).toFixed(1))
    );

    return averages;
  };
  const processData = (data) => {
    const hourlyData = [];
    const keysToAverage = [
      "izci_battery_voltage",
      "izci_seq",
      "izci_sensor_data_in",
      "izci_sensor_data_out",
    ];

    for (let i = 0; i < data.length; i += 24) {
      const hourlyChunk = data.slice(i, i + 24);
      const averageData = calculateAverage(hourlyChunk, keysToAverage);
      averageData.ocak_id = hourlyChunk[0].ocak_id;
      averageData.event_date = hourlyChunk[0].event_date;
      averageData.time = hourlyChunk[0].time;
      hourlyData.push(averageData);
    }
    setfilteredtsdata((prev) => hourlyData);
    console.log("hourlyData", hourlyData);
  };
  const FilterTimeFunction = (timeP, chunk) => {
    var reqTime = new Date();
    var filteredArr;

    switch (timeP) {
      case "1d":
        // Tarihi bir ay öncesine göre
        reqTime.setMinutes(reqTime.getMinutes() - 1440);
        filteredArr = fieldData.tsdata.filter(
          (obje) => Date.parse(obje.time) >= Date.parse(reqTime)
        );
        setfilteredtsdata((prev) => filteredArr);
        if (chunk == "d") {
          processData(filteredArr);
        }
        break;
      case "2d":
        // Tarihi bir ay öncesine göre
        reqTime.setMinutes(reqTime.getMinutes() - 2880);
        filteredArr = fieldData.tsdata.filter(
          (obje) => Date.parse(obje.time) >= Date.parse(reqTime)
        );

        setfilteredtsdata((prev) => filteredArr);
        if (chunk == "d") {
          processData(filteredArr);
        }
        break;
      case "7d":
        // Tarihi bir ay öncesine göre
        reqTime.setMinutes(reqTime.getMinutes() - 10800);
        filteredArr = fieldData.tsdata.filter(
          (obje) => Date.parse(obje.time) >= Date.parse(reqTime)
        );

        setfilteredtsdata((prev) => filteredArr);
        if (chunk == "d") {
          processData(filteredArr);
        }
        break;
      case "14d":
        // Tarihi bir ay öncesine göre
        reqTime.setMinutes(reqTime.getMinutes() - 20160);
        filteredArr = fieldData.tsdata.filter(
          (obje) => Date.parse(obje.time) >= Date.parse(reqTime)
        );

        setfilteredtsdata((prev) => filteredArr);
        if (chunk == "d") {
          processData(filteredArr);
        }
        break;
      case "1m":
        // Tarihi bir ay öncesine göre
        reqTime.setMonth(reqTime.getMonth() - 1);
        filteredArr = fieldData.tsdata.filter(
          (obje) => Date.parse(obje.time) >= Date.parse(reqTime)
        );

        setfilteredtsdata((prev) => filteredArr);
        if (chunk == "d") {
          processData(filteredArr);
        }
        break;
      case "3m":
        reqTime.setMonth(reqTime.getMonth() - 3);
        setfull(prev => false);
        fetch(
           `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${fieldData.id}&type=vana3m`,
          {
            method: "GET",
          }
        )
          .then((response) => response.json())
          .then((result) => {
            console.log("izci ts", result);
            var asd = JSON.stringify(result.body);
            var obj = [];
            result.body.map((e) => {
              obj.push(JSON.parse(e));
            });

            filteredArr = obj.filter(
              (obje) => Date.parse(obje.time) >= Date.parse(reqTime)
            );
            setfilteredtsdata((prev) => filteredArr);
            setChunkSelect((prev) => "d");
            // if (chunk == "d") {
            //   processData(filteredArr);
            // }
            setfull(prev => true);

          })
          .catch((err) => {
            // console.log('devicecard err :', err);
            setfull(prev => true);
          });
        
        break;
      case "6m":
        reqTime.setMonth(reqTime.getMonth() - 6);
        setfull(prev => false);
        fetch(
          `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${fieldData.id}&type=vana6m&created_at=${fieldData.created_at}`,
          {
            method: "GET",
          }
        )
          .then((response) => response.json())
          .then((result) => {
            console.log("izci ts", result);
            var asd = JSON.stringify(result.body);
            var obj = [];
            result.body.map((e) => {
              obj.push(JSON.parse(e));
            });

            filteredArr = obj.filter(
              (obje) => Date.parse(obje.time) >= Date.parse(reqTime)
            );
            setfilteredtsdata((prev) => filteredArr);
            setChunkSelect((prev) => "d");
            // if (chunk == "d") {
            //   processData(filteredArr);
            // }
            setfull(prev => true);

          })
          .catch((err) => {
            // console.log('devicecard err :', err);
            setfull(prev => true);
          });
        
        break;
      case "1y":
        reqTime.setMonth(reqTime.getMonth() - 12);
        filteredArr = fieldData.tsdata.filter(
          (obje) => Date.parse(obje.time) >= Date.parse(reqTime)
        );
        setfilteredtsdata((prev) => filteredArr);
        if (chunk == "d") {
          processData(filteredArr);
        }
        break;
      case "all":
        setfilteredtsdata((prev) => fieldData.tsdata);
        if (chunk == "d") {
          processData(filteredArr);
        }
        break;
    }
  };
  return (
    <div
      ref={myRef}
      className="flex flex-col gap-3   mx-auto max-w-8xl  sm:px-6  max-sm:justify-center  lg:px-8  mb-64"
    >
      <SkeletonTheme baseColor="#d9d9d9" highlightColor="#fff">
        <div
          onClick={() => {
            var navi = document.getElementById("mobilBody");

            if (navi.classList.contains("mobilSmallBody")) {
              return;
            } else {
              // executeScroll();
            }
          }}
          className="max-sm:flex-col max-sm:-mb-0 max-sm:justify-center max-sm:items-start lg:top-3 lg:mb-0 lg:w-full sticky w-11/12 mx-auto top-10  z-50  mt-6 -mb-9 flex flex-row justify-between bg-white  bg-opacity-80 shadow-myShadow1 backdrop-blur-[2rem] rounded-2xl items-center h-16 py-11 px-4"
        >
          <div className="max-sm:flex max-sm:flex-row max-sm:justify-center max-sm:items-baseline max-sm:gap-2">
            <p className="t font-semibold  text-base text-textDark ">{deviceid}</p>
            <p className=" font-medium  text-sm text-textLight -mt-1 ">
              {fieldTown}
            </p>
          </div>

          <div className="max-sm:relative max-sm:translate- w-1/3 max-sm:w-full h-8 absolute lg:left-1/2 lg:-translate-x-1/2  flex flex-row gap-1 justify-center items-center text-xs font-normal">
            <div
              onClick={() => {
                if (chunkSelect == "d" && filterSelect != "3m" && filterSelect != "6m") {
                  setChunkSelect((prev) => "h");
                  FilterTimeFunction(filterSelect, "h");
                }
              }}
              className={`${
                chunkSelect == "h"
                  ? "bg-textDark text-white border-none"
                  : "bg-white text-textDark border-[1px]"
              } hover:cursor-pointer w-1/4 h-full    rounded-md text-center content-center`}
            >
              {t("deviceHourly")}
            </div>
            <div
              onClick={() => {
                if (chunkSelect == "h" && filterSelect != "3m" && filterSelect != "6m") {
                  setChunkSelect((prev) => "d");
                  FilterTimeFunction(filterSelect, "d");
                }
              }}
              className={`${
                chunkSelect == "d"
                  ? "bg-textDark text-white border-none"
                  : "bg-white text-textDark border-[1px]"
              } hover:cursor-pointer w-1/4 h-full    rounded-md text-center content-center`}
            >
              {t("deviceDaily")}
            </div>
            {/* <div
                    onClick={() => FilterTimeFunction("1d")}
                    className="w-1/3 h-full border-[1px] text-center content-center rounded-md ml-12 flex flex-row justify-center items-center gap-1 text-lg"
                  >
                    <ion-icon name="calendar"></ion-icon>{" "}
                    <p className="text-xs">7 gün </p>
                  </div> */}

            <div className="w-5/12 h-full ml-12 max-sm:ml-4">
              <Select
                sx={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                  outline: "none",
                  color: "rgb(69 74 115)",
                  fontFamily: "unset",
                  fontSize: "0.75rem",
                  ":hover": {
                    cursor: "pointer",
                  },
                  borderColor: "none",
                  fieldset: {
                    borderColor: "#e5e7eb",
                  },
                }}
                id="demo-simple-select12"
                value={filterSelect}
                onChange={(event) => {
                  setFilterSelect(event.target.value);
                  FilterTimeFunction(event.target.value, chunkSelect);
                }}
                variant="outlined"
                startAdornment={
                  <div className="flex  justify-center text-center mr-2 text-base ">
                    <ion-icon name="calendar"></ion-icon>
                  </div>
                }
              >
                <MenuItem value={"1d"}>{t("device1Day")}</MenuItem>
                <MenuItem value={"2d"}>{t("device2Day")}</MenuItem>
                <MenuItem value={"7d"}>{t("device7Day")}</MenuItem>
                <MenuItem value={"14d"}>{t("device14Day")}</MenuItem>
                <MenuItem value={"1m"}>{t("device1Month")}</MenuItem>
                <MenuItem value={"3m"}>{t("device3Month")}</MenuItem>
                <MenuItem value={"6m"}>{t("device6Month")}</MenuItem>
              </Select>
            </div>
          </div>
        </div>
        <div className="flex flex-col max-sm:flex-col gap-3 ">
          <div className="flex flex-row justify-end items-end max-sm:flex-col max-sm:w-full max-sm:gap-3 gap-12  ">
            
              <div className=" w-full  max-sm:w-full [&_.deviceid]:hidden  [&_.devicecardcontainer]:p-0 ">
                <DeviceVanaCard data={fieldData} selectedDevice={true} />
              </div>
            

            
              <div className=" w-fit max-sm:w-full  h-fit ">
                <WeatherCard
                  lat={fieldData?.coords?.lat}
                  lon={fieldData?.coords?.lon}
                  lang={i18n.language}
                />
              </div>
            
          </div>
        </div>
        {full ? (
          <div className="flex flex-col max-sm:flex-col gap-3 max-sm:p-1 h-1/2">
            <div className=" w-full max-sm:-ml-6 max-sm:w-[calc(100%+3rem)]  bg-white pt-4 rounded-xl">
              <p className="relative z-20 text-md text-textDark font-semibold  p-3 ">
                {t("Bar")}{" "}
              </p>
              {DrawStackedTSSM(filteredtsdata)}
            </div>
          </div>
        ) : (
          <div className="w-full flex flex-col max-sm:flex-col gap-3">
            
            <div className="w-full ">
              <Skeleton width={"100%"} height={370} />
            </div>
            <div className="w-full ">
              <Skeleton width={"100%"} height={370} />
            </div>
          </div>
        )}
      </SkeletonTheme>
    </div>
  );
}

export default SelectedIzci;
function StackedLine(rawdatas, names) {
  let option = {
    // title: {
    //   show: false,
    //   text: ' asd',
    //   display: 'none',
    // },

    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    legend: {
      type: "plain",
      top: "0%",
      padding: [
        0, // up
        0, // right
        0, // down
        45, // left
      ],
      icon: "roundRect",
    },
    toolbox: {
      show: false,
      feature: {
        dataView: { show: false, readOnly: false },
        saveAsImage: {},
      },
      top: "12%",
      z: 100000,
    },
    grid: [
      {
        left: "3%",
        right: "3%",
        bottom: "0%",
        top: "10%",
        height: "80%",
        containLabel: true,
      },
    ],
    axisLine: {
      show: false,
    },
    xAxis: {
      type: "time",
      boundaryGap: false,
    },
    yAxis: {
      splitLine: { show: false },
      splitArea: {
        show: true,
      },
      axisLine: { show: true },
      type: "value",
      max: function (value) {
        return (value.max + 0.1).toFixed(1);
      },
      min: function (value) {
        return (value.min - 0).toFixed(0);
      },
      axisTick: {
        inside: false,
      },
      axisLabel: {
        inside: false,
        formatter: "{value}\n",
      },
    },
    dataZoom: [
      {
        type: "inside",
        throttle: 10,
        show: true,
        brushSelect: false,
        handleStyle: {
          borderColor: "rgba(0, 0, 0, 1)",
        },
        handleIcon: "roundRect",
        handleSize: "61%",
      },
    ],
    series: [],
    seriesCnt: "6",
    backgroundColor: "rgba(255,255,255,0)",
    titleColor: "#000000",
    subtitleColor: "#505050",
    textColorShow: false,
    textColor: "#333",
    markTextColor: "#ffffff",
    color: [
      "#3b82f6",
      "#454a73",
      "#94d2bd",
      "#ca6702",
      "#bb3e03",
      "#ae2012",
      "#9b2226",
      "#0a9396",
      "#454a73",
    ],
    borderColor: "#ccc",
    borderWidth: "0",
    visualMapColor: ["#8a7ca8", "#e098c7", "#cceffa"],
    legendTextColor: "#000000",
    kColor: "#e098c7",
    kColor0: "transparent",
    kBorderColor: "#e098c7",
    kBorderColor0: "#8fd3e8",
    kBorderWidth: "2",
    lineWidth: "1",
    symbolSize: "0",
    symbol: "emptyRoundRect",
    symbolBorderWidth: "0",
    lineSmooth: false,
    graphLineWidth: 1,
    graphLineColor: "#aaaaaa",
    mapLabelColor: "#000",
    mapLabelColorE: "#ffffff",
    mapBorderColor: "#444",
    mapBorderColorE: "#444",
    mapBorderWidth: 0.5,
    mapBorderWidthE: 1,
    mapAreaColor: "#eee",
    mapAreaColorE: "#e098c7",
    axes: [
      {
        type: "all",
        name: "通用坐标轴",
        axisLineShow: true,
        axisLineColor: "#000000",
        axisTickShow: false,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#000000",
        splitLineShow: false,
        splitLineColor: ["#eeeeee", "#333333"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
      },
      {
        type: "category",
        name: "类目坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: false,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "value",
        name: "数值坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "log",
        name: "对数坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "time",
        name: "时间坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
    ],
    axisSeperateSetting: false,
    toolboxColor: "#4100ff",
    toolboxEmphasisColor: "#000000",
    tooltipAxisColor: "#0013ff",
    tooltipAxisWidth: "3",
    timelineLineColor: "#8fd3e8",
    timelineLineWidth: 1,
    timelineItemColor: "#ff0000",
    timelineItemColorE: "#8fd3e8",
    timelineCheckColor: "#8fd3e8",
    timelineCheckBorderColor: "#8a7ca8",
    timelineItemBorderWidth: 1,
    timelineControlColor: "#8fd3e8",
    timelineControlBorderColor: "#8fd3e8",
    timelineControlBorderWidth: 0.5,
    timelineLabelColor: "#8fd3e8",
    datazoomBackgroundColor: "rgba(0,0,0,0)",
    datazoomDataColor: "rgba(255,255,255,0.3)",
    datazoomFillColor: "rgba(167,183,204,0.4)",
    datazoomHandleColor: "#a7b7cc",
    datazoomHandleWidth: "100",
    datazoomLabelColor: "#333",
  };
  function areafunc(index) {
    if (names.length < 4) {
      console.log("index", index);
      option.series.lineStyle = { width: 0 };
      return {
        opacity: 1,
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: option.color[index],
          },
          {
            offset: 1,
            color: "rgb(255, 255, 255,0)",
          },
        ]),
      };
    } else {
      return {
        opacity: 0.0,
      };
    }
  }
  function linefunc(params) {
    if (names.length == 1) {
      return 1;
    } else {
      return 3;
    }
  }
  rawdatas.map((e, index) => {
    option.series.push({
      name: names[index],
      showSymbol: false,
      type: "line",

      smooth: true,
      data: e,
      lineStyle: {
        width: linefunc(),
      },
      emphasis: {
        focus: "series",
      },
      areaStyle: areafunc(index),
    });
  });
  return (
    <ReactEcharts
      option={option}
      className=" !h-full w-full z-10"
      opts={{ renderer: "canvas" }}
      lazyUpdate={true}
    />
  );
}
