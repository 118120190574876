import React from "react";
import DeviceCard from "./DeviceCard2";
import DeviceOcakCard from "./DeviceOcakCard";
import { Link } from "react-router-dom";
import InstallDevice from "../assets/installnewdevice.svg";
import FarmerWoman from "../assets/farmerwoman.png";
import DeviceVanaCard from "./DeviceVanaCard2";
import DevicePompaCard from "./DevicePompaCard";
import DeviceTofCard from "./DeviceTofCard";
import { useTranslation } from "react-i18next";
import VanaStroke from "../assets/vanaStroke.svg";

function Devices({ data }) {
  const { t, i18n } = useTranslation();

  data?.devices?.sort((a, b) =>
    a.izci_length > b.izci_length ? -1 : b.izci_length > a.izci_length ? 1 : 0
  );
  data.devices = data?.devices?.filter(
    (obj) => obj?.user_deleted_at == undefined || obj?.user_deleted_at == false
  );
  console.log(
    "seribak",
    data?.devices?.filter(
      (obj) =>
        obj?.user_deleted_at == undefined || obj?.user_deleted_at == false
    )
  );
  console.log("seribak2", data.devices);
  return (
    <div className="flex flex-row max-sm:flex-col w-full mt-2  flex-wrap ">
      {data &&
      data.devices &&
      data.devices.some(
        (item) =>
          item?.type === "izci" ||
          item?.type === "tof" ||
          item?.type === "ocak" ||
          item?.type === "vana" ||
          item?.type === "pompa"
      ) ? (
        <>
          <p className=" leading-none text-base font-semibold  text-textDark">
            {t("irrigation")}
          </p>
          <p className=" text-xs pb-3 text-textLight">
          Set the perfect irrigation from seeding to harvest.
          </p>
          <div className=" flex flex-row flex-wrap gap-4 max-sm:gap-4 max-sm:justify-between  w-full">
            {data?.devices
              ? data?.devices?.map((e) => {
                  if (e?.type == "tof") {
                    return (
                      <DeviceTofCard key={e.id} data={e} fieldData={data} />
                    );
                  }
                })
              : ""}
            {data?.devices
              ? data?.devices?.map((e) => {
                  if (e?.type == "pompa") {
                    return (
                      <DevicePompaCard key={e.id} data={e} fieldData={data} />
                    );
                  }
                })
              : ""}
            {data?.devices
              ? data?.devices?.map((e) => {
                  if (e?.type == "vana") {
                    return (
                      <div className="max-w-[24rem] w-[calc(50%-0.5rem)]"><DeviceVanaCard key={e.id} data={e} fieldData={data} /></div>
                    );
                  }
                })
              : ""}
            {data?.devices?.some(
              (item) => item?.type === "pompa" || item?.type === "vana"
            ) ? (
              ""
            ) : (
              <div className="w-full flex flex-col   z-0 rounded-t-md items-center justify-center ">
                <img src={VanaStroke} className="w-20  mb-5" />
                <p className="text-lg font-semibold text-textDark">
                  {t("devicesNoDevice")}
                </p>
                <p className="text-sm font-medium w-3/4 text-center text-textLight">
                  {t("devicesInstallDevice")}
                </p>
              </div>
            )}
          </div>

          <p className="text-textDark font-semibold text-base mb-4 mt-8 w-full ">
            {t("sensors")}
          </p>
          {/* {data?.devices
            ? data?.devices?.map((e) => {
                if (e.type == "ocak") {
                  return (
                    <DeviceOcakCard key={e.id} data={e} fieldData={data} />
                  );
                }
              })
            : ""} */}

          {data?.devices
            ? data?.devices?.map((e) => {
                if (e?.type == "izci") {
                  return <DeviceCard key={e.id} data={e} fieldData={data} />;
                }
              })
            : ""}
          {data?.devices?.some(
            (item) =>
              item?.type === "izci" ||
              item?.type === "tof" ||
              item?.type === "ocak"
          ) ? (
            ""
          ) : (
            <div className="w-full flex flex-col   z-0 rounded-t-md items-center justify-center ">
              <img src={VanaStroke} className="w-20  mb-5" />
              <p className="text-lg font-semibold text-textDark">
                {t("devicesNoDevice")}
              </p>
              <p className="text-sm font-medium w-3/4 text-center text-textLight">
                {t("devicesInstallDevice")}
              </p>
            </div>
          )}
        </>
      ) : (
        <div className="w-full p-6 flex flex-col border  border-dashed  z-0 rounded-t-md items-center justify-center ">
          <img src={VanaStroke} className="w-20  mb-5" />
          <p className="text-base font-semibold text-textDark">
            {t("devicesNoDevice")}
          </p>
          <p className="text-xs  w-3/4 text-center text-textLight">
            {t("devicesInstallDevice")}
          </p>
          <Link
            to="/setupDevice"
            className="text-sm font-semibold px-10 py-3 bg-blue-500 text-white mt-2 rounded-md"
          >
            {t("navSetupDevice")}
          </Link>
        </div>
      )}

      {/* <div className="p-2  ">
        <Link to="/setupDevice" className="  w-full h-full ">
          <div className="h-full bg-white shadow-myShadow1 rounded-2xl p-16 flex flex-col justify-center items-center">
            <img src={InstallDevice} className="w-32 mb-5" />
            <img src={FarmerWoman} className="w-20" />
          </div>
        </Link>
      </div> */}
    </div>
  );
}

export default Devices;
