import React, { useEffect, useState } from "react";
import vanaBlack from "../assets/vanaBlack.svg";
import { useTranslation } from "react-i18next";

function HomeVanaCard({ data }) {
  const { t, i18n } = useTranslation();

  const [vanaon, setvanaon] = useState(0);
  useEffect(() => {
    console.log("HomeVanaCard", data);
    data?.map((e) => {
      e.status == "on" && setvanaon((prev) => prev + 1);
    });
  }, []);

  return (
    <div className="bg-white rounded-xl shadow-myShadow1 flex flex-col w-44 h-full justify-center items-center">
      <div className="flex flex-col   h-1/2 w-full  justify-center items-center">
        <img src={vanaBlack} className="w-16 mb-4" />
        <p className="text-base text-textDark font-semibold">{t("valves")}</p>
      </div>
      <div className="border-b w-9/12 "></div>
      <div className="w-full h-1/2 justify-center items-center flex flex-col">
        {vanaon === 0 ? (
          <div className="flex flex-col gap-2  text-xl text-textLight justify-center items-center ">
            <ion-icon name="water-outline"></ion-icon>

            <p className="text-sm text-textLight ">{t("noOpenValve")}</p>
          </div>
        ) : (
          <div className="flex flex-col  justify-center items-center ">
            <p className="text-base text-textDark font-semibold">{vanaon}</p>
            <p className="text-sm text-textLight ">{t("open")}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default HomeVanaCard;
